<!-- 個人訊息 -->
<!-- TODO: 版面待美化 -->
<template lang="pug">
v-row(style="height: 100%" no-gutters)
  v-col(cols="4")
    //- 訊息列表
    msgList(ref='msgList' v-model="selectedMessage")
  v-col
    //- 訊息內容
    msgContent(
      v-if="selectedMessage"
      :message="selectedMessage"
    )
    v-card(flat outlined height='100%' v-else)
</template>

<script>
import Vue from 'vue'
import msgList from './list.vue'
import msgContent from './message.vue'

export default Vue.extend({
  components: {
    msgList, msgContent
  },
  data () {
    return {
      // 選中的訊息
      selectedMessage: null
    }
  }
})
</script>
