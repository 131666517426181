import importPages from './import-pages'
import request from '@/utils/request'

/**
 * 測試頁
 */
const devRoutes = {
  icon: 'alert-circle',
  name: 'test',
  lang: {
    en: 'Test page',
    cn: '測試頁'
  },
  children: [
    {
      name: 'drag-drop',
      lang: {
        en: 'Drag-drop',
        cn: '拖拉測試'
      },
      to: 'TestSortable',
      page: () => import('@/views/Test/TestSortable.vue'),
      permissions: []
    },
    {
      name: 'TestDatePicker',
      lang: {
        en: 'TestDatePicker',
        cn: '時間選擇測試'
      },
      to: 'TestDatePicker',
      page: () => import('@/views/Test/TestDatePicker/index.vue'),
      permissions: []
    },
    {
      name: 'TestDateTimePicker2',
      lang: {
        en: 'TestDateTimePicker2',
        cn: '時間選擇測試'
      },
      to: 'TestDateTimePicker2',
      page: () => import('@/views/Test/TestDateTimePicker2/index.vue'),
      permissions: []
    },
    {
      name: 'TestDataTableAjax',
      lang: {
        en: 'TestDataTableAjax',
        cn: 'DataTable 取資料測試'
      },
      to: 'TestDataTableAjax',
      page: () => import('@/views/Test/TestDataTableAjax/index.vue'),
      permissions: []
    },
    {
      name: 'TestSavingOverlay',
      lang: {
        en: 'TestSavingOverlay',
        cn: '測試「存檔中」訊息'
      },
      to: 'TestSavingOverlay',
      page: () => import('@/views/Test/TestSavingOverlay.vue'),
      permissions: []
    },
    {
      name: 'TestMakeValidator',
      lang: {
        en: 'TestMakeValidator',
        cn: '測試表單驗證規則'
      },
      to: 'TestMakeValidator',
      page: () => import('@/views/Test/TestMakeValidator.vue'),
      permissions: []
    },
    {
      name: 'TestDialog',
      lang: {
        en: 'TestDialog',
        cn: '測試彈跳視窗'
      },
      to: 'TestDialog',
      page: () => import('@/views/Test/TestDialog/index.vue'),
      permissions: []
    },
    {
      name: 'TestEncryptField',
      lang: {
        en: 'TestEncryptField',
        cn: '測試加密欄位'
      },
      to: 'TestEncryptField',
      page: () => import('@/views/Test/TestEncryptField/index.vue'),
      permissions: []
    }
  ]
}

/**
 * 注入測試用的選單 + 畫面
 */
function install () {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  // 注入版面
  for (const pageItem of devRoutes.children) {
    console.info('注入選單資料', pageItem)
    importPages[pageItem.to] = pageItem.page
  }

  // 注入 ajax 拉選單
  request.interceptors.response.use(function (response) {
    // @ts-ignore
    if (response._raw.config.url === '/Me/nav') {
      response.data.push(devRoutes)
    }
    return response
  })
}

export { install }
