<!-- 演示模式浮水印 -->
<template lang="pug">
div.demo-watermark_wrap
  slot
  div.demo-watermark(ref="mask").ma-0
    //- 如果 Canvas 失效的話，改用文字顯示
    div.demo-watermark-fallback(v-if="fallback" v-for="i in 10")
      | {{ title }}
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    title: 'TEST DEMO',
    fallback: false
  }),
  methods: {
    drawCanvas (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 320
      canvas.height = 180
      const ctx = canvas.getContext('2d')
      ctx.font = '48px Arial'
      ctx.fontWeight = '900'
      ctx.fillStyle = 'black'
      ctx.rotate(-20 * Math.PI / 180)
      ctx.fillText(text, 0, 180)
      const base64 = canvas.toDataURL()
      return base64
    }
  },
  mounted () {
    // 如果全域設定不是 demo 模式，就不要顯示
    if (!window.demoWatermark) {
      return
    }

    try {
      const base64 = this.drawCanvas(this.title)
      this.$refs.mask.style.backgroundImage = `url(${base64})`
    } catch (err) {
      console.error(err)
      this.fallback = true
    }
  }
})
</script>

<style lang="sass" scoped>
.demo-watermark_wrap
  position: relative

.demo-watermark
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none
  z-index: 9999
  background-repeat: repeat
  opacity: 0.08

.demo-watermark-fallback
  color: black
  text-align: center
  font-size: 80px
  margin-bottom: 120px
</style>
