/**
 * 視窗大小
 */
const state = {
  x: window.innerWidth,
  y: window.innerHeight
}

window.addEventListener('resize', function (event) {
  state.x = window.innerWidth
  state.y = window.innerHeight
}, true)

export default {
  namespaced: true,
  state
}
