import request from '@/utils/request'
import ResponseFormat from './response-format'

const url = {
  wallet: '/UserInfo/wallet',
  domainList: '/Me/domainList',
  srcAccount: '/UserInfo/srcAccount',
  referral: '/UserInfo/referral',
  referralAdd: '/UserInfo/referralAdd',
  referralEdit: '/UserInfo/referralEdit',
  referralDel: '/UserInfo/referralDel',
  imTypeList: '/Me/imTypeList',
  imList: '/UserInfo/imList',
  imAdd: '/UserInfo/imAdd',
  imEdit: '/UserInfo/imEdit',
  imDel: '/UserInfo/imDel',
  inboxPost: '/UserInfo/inbox',
  online: '/UserInfo/online',
  accountEnabled: '/UserInfo/accountEnabled',
  depositAmount: '/UserInfo/depositAmount'
}

// 錢包
export interface walletItem {
  amount: number
}
export function wallet () {
  return request({
    url: url.wallet,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<walletItem>>
}
// 推廣鏈結 - 網址下拉選單資料
export interface domainListItem {
  data: []
}
export function domainList () {
  return request({
    url: url.domainList,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<domainListItem[]>>
}
// 推廣鏈結 - 查詢要複製的直屬玩家帳號是否存在
export interface srcAccountItem {
  account: string
}
export function srcAccount (data: srcAccountItem) {
  return request({
    url: url.srcAccount,
    method: 'get',
    params: {
      account: data
    }
  }) as unknown as Promise<ResponseFormat<srcAccountItem>>
}
// 推廣鏈結 - 列表資料
export interface referralItem {
  id: number
  title: string
  copy_account: string
  code: string
  register_url: string
}
export function referral () {
  return request({
    url: url.referral,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<referralItem[]>>
}
// 推廣鏈結 - 新增列表
export interface referralAddItem {
  title: string
  code: string
  copy_id: number
  copy_account: string
  domain: string
}
export function referralAdd (data: referralAddItem) {
  return request({
    url: url.referralAdd,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<{}>>
}

// 推廣鏈結 - 編輯列表
export interface referralEditItem {
  id: number
  user_id: number
  title: string
  code: string
}
export function referralEdit(data: referralEditItem) {
  return request({
    url: url.referralEdit,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<{}>>
}

/**
 * 推廣鏈結 - 刪除列表
 * @param id
 * @returns
 */
export function referralDel (id: number) {
  return request({
    url: url.referralDel,
    method: 'post',
    data: { id }
  }) as unknown as Promise<ResponseFormat<void>>
}
// 通訊軟體 - 通讯软体类型
export function imTypeList () {
  return request({
    url: url.imTypeList,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<[]>>
}
// 通訊軟體 - 通讯列表
export interface imListItem {
  id: number
  type: string
  title: string
  link: string
  qrcode_path: string
}
//
export function imList () {
  return request({
    url: url.imList,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<imListItem[]>>
}
// 通訊軟體 - 新增通讯列表
export interface imAddItem {
  type: string
  title: string
  link: string
}
export function imAdd (data: imAddItem) {
  return request({
    url: url.imAdd,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}
// 通訊軟體 - 編輯通讯列表
export interface imEditItem {
  contact_id: number
  type: string
  title: string
  link: string
}
export function imEdit(data: imEditItem) {
  return request({
    url: url.imEdit,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}
/**
 * 通訊軟體 - 刪除通讯列表
 * @param id id
 * @returns
 */
export function imDel (id: number) {
  return request({
    url: url.imDel,
    method: 'post',
    data: {
      contact_id: id
    }
  }) as unknown as Promise<ResponseFormat<void>>
}

/**
 * 站內信
 * @param receiver 收件者類型
 * @param title 標題
 * @param content 內容
 * @returns
 */
export function inboxPost (receiver: number[], title: string, content: string) {
  return request({
    url: url.inboxPost,
    method: 'post',
    data: {
      receiver: receiver.join(','),
      title,
      content
    }
  }) as unknown as Promise<ResponseFormat<void>>
}

/**
* 查詢帳號是否可以被使用
*/
export function accountEnabled (account: string) {
  return request({
    url: url.accountEnabled,
    method: 'get',
    params: { account }
  }) as unknown as Promise<ResponseFormat<boolean>>
}

/**
 * 充值額度
 */
export function depositAmount () {
  return request({
    url: url.depositAmount,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<void>>
}
