import request from '@/utils/request'
import ResponseFormat from './response-format'

const url = {
  view: '/WithdrawalTransfer/view',
  reject: '/WithdrawalTransfer/reject',
  audit: '/WithdrawalTransfer/audit',
  lock: '/WithdrawalTransfer/lock',
  unlock: '/WithdrawalTransfer/unlock',
  examine: '/WithdrawalTransfer/examine',
  detail: '/WithdrawalTransfer/detail',
  query: '/WithdrawalTransfer/query',
  rate: '/WithdrawalTransfer/rate',
  getNotifications: '/WithdrawalTransfer/getNotifications'
}

/**
 * 筛选查询
 */
export interface viewItem {
  account: string
  agent_account: string
  order_id: number,
  status: string,
  start: number,
  end: number
  exp_flag: number // 不传单纯查询，汇出传1
}
export function getView (params: viewItem) {
  return request({
    url: url.view,
    method: 'get',
    params
  }) as unknown as Promise<ResponseFormat<viewItem[]>>
}
/**
 * 下載
 */
export function download (params: viewItem) {
  return request({
    url: url.view,
    method: 'get',
    params
    // responseType: 'blob',
    // headers: { 'Content-Type': 'application/json; application/octet-stream' },
    // timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<viewItem[]>>
}

/**
 * 主要參數
 */
export interface commonItem {
  id: number,
  remark: string
}
/**
 * 拒絕
 */
export function reject (parameter: commonItem) {
  return request({
    url: url.reject,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 審核參數
 */
export interface auditItem {
  id: number,
  remark: string,
  pay_id: number
}
/**
 * 审核
 */
export function audit (parameter: auditItem) {
  return request({
    url: url.audit,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 鎖定
 */
export function lock (parameter: commonItem) {
  return request({
    url: url.lock,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 解鎖
 */
export function unlock (parameter: commonItem) {
  return request({
    url: url.unlock,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 出款
 */
export function examine (parameter: commonItem) {
  return request({
    url: url.examine,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 詳細資料
 */
export function detail (parameter: commonItem) {
  return request({
    url: url.detail,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 查詢上游
 */
export function query (parameter: commonItem) {
  return request({
    url: url.query,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}

/**
 * 取得待處理數量
 */
export function getNotifications () {
  return request({
    url: url.getNotifications
  }) as Promise<ResponseFormat<Number>>
}

/**
 * 取得手续费
 */
export function rate (parameter: commonItem) {
  return request({
    url: url.rate,
    method: 'post',
    data: parameter
  }) as unknown as Promise<ResponseFormat<commonItem[]>>
}
