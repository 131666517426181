import { DAY_OF_WEEK } from '@/constants'
import moment, { MomentInput } from 'moment'
import isFinite from 'lodash/isFinite'

// 初始化
const KEY = 'app'
moment.defineLocale(KEY, { week: { dow: DAY_OF_WEEK } })

export const FORMAT = {
  YMDHMSZ: 'YYYY-MM-DD HH:mm:ss ZZ',
  YMDHMS: 'YYYY-MM-DD HH:mm:ss',
  YMDHM: 'YYYY-MM-DD HH:mm',
  YMD: 'YYYY-MM-DD'
}

export const newFORMAT = {
  YMDHMS: 'YYYY-MM-DDTHH:mm:ss',
  YMDHM: 'YYYY-MM-DDTHH:mm',
  tYMDHMS: '0000-00-00T00:00:00',
  tYMDHM: '0000-00-00T00:00'
}

export default {
  install (Vue, options) {
    Vue.mixin({
      methods: {
        /**
         * 取當下時間
         * @param data
         * @param tz 時區
         * @returns moment
         */
        getMoment (data: Date | Number = new Date(), tz = null) {
          // @ts-ignore
          const store = this.$store
          // @ts-ignore
          tz = (tz || store.getters.timezone || 0)
          if (isFinite(data)) {
            // @ts-ignore
            data = new Date(data * 1000)
          }
          return moment(data as MomentInput).locale(KEY).utcOffset(tz as unknown as string | number)
        },

        /**
         * 轉 timestamp 成字串
         * @param timestamp
         * @param format
         * @returns string
         */
        unixTimestampFormat (timestamp: number, format = FORMAT.YMDHMS) {
          const ref = this.getMoment()
          return moment.unix(timestamp).utcOffset(ref.utcOffset()).format(format)
        },

        /**
         * * 轉 timestamp 成字串(不顯示秒數)
         * @param timestamp
         * @returns
         */
        unixTimestampFormatYMDHM (timestamp: number) {
          const ref = this.getMoment()
          return moment.unix(timestamp).utcOffset(ref.utcOffset()).format(FORMAT.YMDHM)
        },

        /**
        * * 轉成該時區的 timestamp for api
        * @param timestamp
        * @returns
        */
        unixTimezoneStamp (timestamp: number, tz: string | number) {
          const ref = this.getMoment(timestamp).utcOffset(tz, true).unix()
          return ref
        },

        /**
        * *  把 timestamp 轉成該時區的時間格式 'YYYY-MM-DD HH:mm:ss ZZ' for ui
        * @param timestamp
        * @returns
        */
        timezoneStampFormatYMDHMSZ (timestamp: number, tz: string | number) {
          const ref = this.getMoment(timestamp).utcOffset(tz).format(FORMAT.YMDHMSZ)
          return ref
        },

        /**
        * *  把 timestamp 轉成該時區的時間格式 'YYYY-MM-DD' for ui
        * @param timestamp
        * @returns
        */
        timezoneStampFormatYMD (timestamp: number, tz: string | number) {
          const ref = moment.unix(timestamp).utcOffset(tz).format(FORMAT.YMD)
          return ref
        },

        /**
         * 轉換後端吐回來的格式
         * @param item 要轉換的項目
         * @param opts 轉換選項
         */
        unixTimestampToDateTime (item: Object, opts: { format?: string, fields?: string[] } = {}) {
          opts = {
            format: FORMAT.YMDHMS,
            fields: ['create_time', 'update_time', 'reward_time', 'bet_time'],
            ...opts
          }
          for (const field of opts.fields!) {
            if (item[field]) {
              item[field] = this.unixTimestampFormat(item[field], opts.format)
            }
          }
        }
      }
    })
  }
}
