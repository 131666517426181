import * as typings from './typings'

const ICON: typings.ICON = {
  ICON_OK: 0,
  ICON_WARN: 1,
  ICON_ERROR: 2
}
const IMGTYPE: typings.IMGTYPE = {
  IMG_CONTACT: 0,
  IMG_CONTACT2: 1
}

export { ICON, IMGTYPE }
