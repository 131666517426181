/**
 * 向后端请求用户的菜单，动态生成路由
 */
import router, { resetRouter } from '@/router'
import defaultRoute, { defaultLoggedInRoute } from '@/router/default-route'
import { generateRouteConfig } from '@/router/generator-routers'
import * as authAPI from '@/api/auth'
import i18n from '@/i18n'

const permission = {
  state: {
    routers: [],
    nav: []
  },
  mutations: {
    SET_ROUTERS: (state, routerMenu: authAPI.Menu.AjaxData) => {
      // 把語言資料加入 i18n 中
      for (const mainMenu of routerMenu) {
        for (const lang in mainMenu.lang) {
          // 主選單
          const langKey = `nav.${mainMenu.name}`
          const langVal = mainMenu.lang[lang]
          i18n.mergeLocaleMessage(lang, { [langKey]: langVal })
        }
        // 子選單
        for (const child of mainMenu.children) {
          for (const lang in child.lang) {
            const langKey = `nav.${child.name}`
            const langVal = child.lang[lang]
            i18n.mergeLocaleMessage(lang, { [langKey]: langVal })
          }
        }
      }

      // 關閉 CASH 頁面的 flag
      if (process.env.VUE_APP_DISABLE_CASH_PAGE) {
        const disableMenu = ['cashManager', 'cashSettings']
        routerMenu = routerMenu.filter(m => disableMenu.indexOf(m.name) === -1)
      }

      const routers = generateRouteConfig(routerMenu)
      state.nav = routerMenu

      state.addRouters = routers
      state.routers = [...defaultLoggedInRoute, ...defaultRoute, ...routers]

      resetRouter()
      state.routers.forEach(r => router.addRoute(r))
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return authAPI
        .getCurrentUserNav('')
        .then(res => {
          commit('SET_ROUTERS', res.data)
        })
        .catch(err => {
          console.error(err)
          alert(JSON.stringify({
            message: '错误',
            description: '请求用户信息失败，请重试'
          }))
        })
    },
    // 重置最基本的路由
    ResetRouter ({ commit }) {
      commit('SET_ROUTERS', [])
    }
  }
}

export default permission
