/**
 * 依據 route.meta.permissions 中判斷是否有此權限
 * 沒有的話就不顯示
 */
export default {
  install (Vue, options) {
    Vue.mixin({
      props: {
        showIfRouteMetaHasPermission: {
          type: [String, null],
          default: null
        }
      },
      mounted () {
        let found = false
        if (this.showIfRouteMetaHasPermission) {
          const p: string[] = this.$route.meta.permissions || []
          found = p.indexOf(this.showIfRouteMetaHasPermission) > -1
        } else {
          found = true
        }

        if (!found) {
          this.$el.style.display = 'none'
        }
      }
    })
  }
}
