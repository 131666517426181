<!-- 確認彈跳視窗 -->
<template lang="pug">
v-dialog(
  v-model='show'
  max-width='300px'
  content-class="elevation-0"
  persistent
)
  v-card
    template(v-if='!imgOpen')
      .icon-sure(v-if="icon == ICON.ICON_OK")
      .icon-warning(v-else-if="icon == ICON.ICON_WARN")
      .icon-danger(v-else-if="icon == ICON.ICON_ERROR")
    template(v-else)
      .img-contact-us(v-if="imgType === IMGTYPE.IMG_CONTACT")
    v-card-text.text-h5.text-center.py-2(v-if="title !== false") {{ computedTitle }}
    v-card-text.text-h7.text-center.pt-0(v-html='content')
    v-card-actions.justify-center
      v-btn(
        v-for="(item, key) in buttons"
        :color='item.color'
        width='120px'
        text
        @click='clickButton(item)'
      ) {{ item.text }}
</template>

<script>
import { ICON, IMGTYPE } from './constants'
import Vue from 'vue'

export default Vue.extend({
  props: {
    icon: {
      type: Number,
      default: 0
    },
    title: {
      type: [String, Boolean],
      default: null
    },
    content: {
      type: [String, Boolean],
      default: null
    },
    imgType: {
      type: Number,
      default: null
    },
    imgOpen: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Array,
      default: () => ([
        {
          text: this.$t('button.ok'),
          color: '',
          callback: function (dialog) { dialog.close() }
        }
      ])
    }
  },
  data: () => ({
    ICON,
    IMGTYPE,
    show: true
  }),
  methods: {
    clickButton (item) {
      if (item.callback) {
        item.callback(this)
      } else {
        this.close()
      }
    },
    close () {
      this.show = false
      this.$emit('close')
    }
  },
  computed: {
    computedTitle () {
      const { title } = this
      return (title === true || title === null) ? this.$t('dialog.confirm') : title
    }
  }
})
</script>

<style lang="sass" scoped>
.icon-sure
  user-select: none
  background-image: url(@/assets/images/sure.png)
  height: 150px
  background-position: center

.icon-warning
  user-select: none
  background-image: url(@/assets/images/warn.png)
  height: 150px
  background-position: center

.icon-danger
  user-select: none
  background-image: url(@/assets/images/danger.png)
  height: 150px
  background-position: center

.img-contact-us
  user-select: none
  background-image: url(@/assets/images/contactUs.png)
  height: 240px
  background-position: center -19px
</style>
