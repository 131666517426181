export default {
  namespaced: true,
  state: {
    // webSocket 連線狀態
    connected: false,
    // 在線使用者
    onlineUserCount: {}
  },
  mutations: {
    // webSocket 連線狀態
    connected (state, connected: boolean) {
      state.connected = connected
    },
    // 在線使用者統計
    onlineUserCount (state, onlineUserCount: Object) {
      state.onlineUserCount = onlineUserCount
    }
  }
}
