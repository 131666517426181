import Vue from 'vue'

/**
 * 最小字元長度
 * @param strLen 長度字元
 */
export default function strlenMin (this: Vue, strLen: number) {
  return (v) => {
    if (!v) {
      return true
    } else if (strLen > v.toString().length) {
      return this.$t('validate.strlenMin', { length: strLen })
    }
    return true
  }
}
