<!-- 顯示金額用 -->
<template lang="pug">
span(
  :class="{ negative: isNegative }"
) {{ displayText }}
</template>

<script>
import Vue from 'vue'
import isString from 'lodash/isString'

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    displayText () {
      const { value } = this
      // 不明格式
      if (isNaN(parseFloat(value))) {
        return value
      }

      // 字串
      if (isString(value)) {
        const data = value.split('.')
        data[0] = parseFloat(data[0]).toLocaleString('en')
        return data.join('.')
      }

      // 數字，直接轉換後回傳
      return value.toLocaleString('en')
    },
    // 判斷是否為負值
    isNegative () {
      return (this.value + '').substring(0, 1) === '-'
    }
  }
})
</script>

<style lang="sass" scoped>
.negative
  color: red
</style>
