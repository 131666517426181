
import Vue from 'vue'
import isString from 'lodash/isString'
import isNumber from 'lodash/isFinite'

export default Vue.extend({
  props: {
    cols: { type: [Number, String], default: null },
    xs: { type: [Number, String], default: null },
    sm: { type: [Number, String], default: null },
    md: { type: [Number, String], default: null },
    lg: { type: [Number, String], default: null },
    xl: { type: [Number, String], default: null },
    elevation: { type: [Number, String], default: null },
    maxWidth: { type: [Number, String, Boolean], default: 800 }
  },
  computed: {
    colClass () {
      const cssClass: string[] = []
      for (const key of ['xs', 'sm', 'md', 'lg', 'xl']) {
        if (this[key]) {
          cssClass.push(`col-${key}-${this[key]}`)
        }
      }
      return cssClass
    },
    getStyle () {
      const data = {}
      if (this.maxWidth) {
        let val = this.maxWidth
        if (isString(val)) {
          // 什麼事都不做
        } else if (isNumber(val)) {
          val = `${val}px`
        }
        data['max-width'] = val
      }
      return data
    }
  },
  mounted () {
    const parentDOM = this.$el.parentElement
    if (
      parentDOM &&
      parentDOM.classList.contains('v-dialog') &&
      !parentDOM.classList.contains('elevation-0')
    ) {
      console.warn('上級 v-dialog 沒有加上 content-class="elevation-0" 屬性，會有不正常的陰影效果')
    }
  }
})
