<template lang="pug">
v-btn(depressed fab small @click='logout')
  v-icon.appbarIcon--text mdi-logout
</template>

<script lag="ts">
export default {
  methods: {
    logout () {
      this.$store.dispatch('user/Logout')
        .then(() => {
          this.$router.push('/', { replace: true })
          setTimeout(() => {
            //  FIXME: 有時候登入不會自動導向到登入後頁面
            // 所以先用這個方法繞過去
            window.location.reload()
          }, 1)
        })
    }
  }
}
</script>
