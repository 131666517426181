import Vue from 'vue'

/**
 * 比較大小 ( "輸入值(inputValue)" 不可大於 "基準值(referValue)" )
 */
export default function numThanSize (this: Vue, referValue: number) {
  return (inputValue) => {
    // console.log('inputValue', inputValue)
    // console.log('referValue', referValue)

    if (!inputValue) {
      return true
    } else if (Number(inputValue) * 1000 > referValue * 1000) {
      return this.$t('validate.numThanSize', { referValue }) // 不可大於...
    }
    return true
  }
}
