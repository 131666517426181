import request from '@/utils/request'
import ResponseFormat, { Pagination } from './response-format'
import { MultipleLanguage } from './typings'

const url = {
  getAllGame: '/GameAPI/getAllGame',
  getGameHall: '/GameAPI/getGameHall',
  getGameDetail: '/GameAPI/getGameDetail',
  view: '/GameAPI/view',
  editGameDetailStatus: '/GameAPI/editGameDetailStatus', // 單筆修改開關
  editGameDetailHall: '/GameAPI/editGameDetailHall', // 單筆修改館別
  editBetHall: '/GameAPI/editBetHall', // 單筆修改返水南畢館別
  getListBetHall: '/GameAPI/listBetHall', // 取得返水館別列表
  synchronize: '/GameAPI/synchronize',
  synchronizeAll: '/GameAPI/synchronizeAll',
  editGamePlatformDetail: '/GameAPI/editGamePlatformDetail',
  gameMaintenanceView: '/GameMaintenance/view',
  gameMaintenanceEdit: '/GameMaintenance/edit',
  transferOutAllMem: '/GameMaintenance/transferOutAllMem', // 一鍵拉回所有玩家錢包 by 遊戲
  gameApiLogGetFn: '/GameApiLog/getFn',
  viewGameApiLog: '/GameApiLog/view',
  gameApiBetPostBet: '/GameApiBet/postBet',
  gameApiBetRecalculateBetDetail: '/GameApiBet/recalculateBetDetail', // 強制重新計算 bet detail + sbet
  gameApiBetRecalculateSbet: '/GameApiBet/recalculateSbet', // 重新計算 SBET重新計算 SBET
  gameApiBetView: '/GameApiBet/view', // 平台數據資料列表
  getList: '/GameTabManagement/view',
  getIcon: '/GameTabManagement/getIcon',
  gameTabManagementAdd: '/GameTabManagement/add',
  gameTabManagementEdit: '/GameTabManagement/edit',
  gameTabManagementSetStatus: '/GameTabManagement/setStatus',
  gameTabManagementEditContent: '/GameTabManagement/editContent',
  gameTabManagementEditSort: '/GameTabManagement/editSort',
  gameTabManagementDel: '/GameTabManagement/del',
  gameInfoView: '/GameInfo/view',
  gameInfoEdit: '/GameInfo/edit'
}

export interface GetAllGameItem {
  game_id: number
  title_text: string
}
export interface GameDetailReq {
  game_id: number
  isPage: number
}
export interface GetGameDetailItem {
  id: number,
  game_platform_id: number,
  game_id: number,
  game_title: string,
  game_code: string,
  platform: {
    gm: string,
    title: string
  }
  total: number
  per_page: number
  current_page: number
  last_page: number
}
export interface GetGameHallItem {
  hall_id: number
  title_lang: MultipleLanguage
}
export interface getListBetHallItem {
  hall_id: number
  title_lang: MultipleLanguage
}
export interface GetGameDetailReq {
  game_id: number
  isPage: number
}
export interface gameApiLogGetFnItem {
  fn: string
  name: string
}
export interface GetListItemContent {
  title: string
  games: number[]
}
export interface GameTabTitleLang {
  zh_CN: string,
  en_US: string,
  ms_MY: string,
  vi_VN: String
}
export interface GetListItem {
  id: number
  icon_id: number
  icon_id_text: string
  display: number
  status: number
  title: GameTabTitleLang,
  content: { games: number[] } | GetListItemContent[]
}
export interface ViewItem {
  id: number
  game_platform_id: number
  game_title: string
  game_status: number
  game_hall_id: number
  game_code: string
  game_sort: number
  total: number
  per_page: number
  current_page: number
  last_page: number
}
export interface ViewReq {
  game_id: number
  game_name: string
  hall_id: number
  page: number
  limit: number
}
export interface EditGamePlatformDetailItem {
  id: number
  game_id: number
  status: number
  hall_id: number
}
export interface gameMaintenanceViewItem {
  game_id: number
  game_title: string
  status: number
  game_hall_id: string[]
  maintenance_flag: number
  start_date: number
  end_date: number
}
export interface EditGameMaintenanceItem {
  game_id: number
  status: number
  game_hall_id: string[]
  maintenance_flag: number
  maintenance_start: number
  maintenance_end: number
}
export interface GameApiReq {
  gameType: number
  fn: string
  account: string
  start_date: number
  end_date: number
  page: number
  limit: number
}
export interface ViewGameApiItem {
  account: string
  request: string
  respond: string
  create_time: number
  total: number
  per_page: number
  current_page: number
  last_page: number
}
export interface gameApiBetReq {
  gameType: number
  startTime: number
  endTime: number
}
export interface gameApiBetViewItem {
  id: number
  title_text: string
  limit_time: string
}
export interface gameApiBetRecalculateBetDetailReq {
  game_platform_id: number
  start: number
  end: number
}
export interface gameApiBetRecalculateSbetReq {
  game_platform_id: number
  start: number
  end: number
}
export interface GameDetailItem {
  game_id: number
  game_title: string
  game_code: string
}
export interface gameTabManagementAddItem {
  display: number
  icon_id: number
  title: GameTabTitleLang
  status: number
}
export interface gameTabManagementEditItem extends gameTabManagementAddItem {
  id: number
}
export interface gameTabManagementEditContentItem {
  id: number,
  content: string[]
}
export interface gameTabManagementSetStatusItem {
  id: number
  status: number
}
export interface gameInfoItem {
  game_id: number
  title_text: string
  api_info: string[]
}
export interface gameInfoEditItem {
  game_id: number
  min_amount: number
  max_amount: number
  jackpot_initial_value: number
  ApiUrl: string
  Agentid: string
  AgentKey: string
  DC: string
  AgentIv: string
  BetUrl: string
  CasinoKey: string
  AgentSalt: string
  LoginUrl: string
  MobileUrl: string
}

export function getAllGame (params: {
  checkDetail: 1
}) {
  return request({
    url: url.getAllGame,
    method: 'get',
    params
  }) as unknown as Promise<ResponseFormat<Pagination<GetAllGameItem[]>>>
}

export function getGameDetail (data: GameDetailReq) {
  return request({
    url: url.getGameDetail,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<GetGameDetailItem[]>>
}

export function getGameHall () {
  return request({
    url: url.getGameHall,
    method: 'get'
    // data: parameter
  }) as unknown as Promise<ResponseFormat<GetGameHallItem[]>>
}

export function getListBetHall () {
  return request({
    url: url.getListBetHall,
    method: 'get'
    // data: parameter
  }) as unknown as Promise<ResponseFormat<getListBetHallItem[]>>
}

export function view (data: ViewReq) {
  return request({
    url: url.view,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<ViewItem[]>>
}
// 遊戲列表開關(單筆)
export function editGameDetailStatus (id: number, game_id: number, status: number) {
  return request({
    url: url.editGameDetailStatus,
    method: 'post',
    data: { id, game_id, status }
  }) as unknown as Promise<ResponseFormat<void>>
}
// 修改遊戲館別(單筆)
export function editGameDetailHall (id: number, game_id: number, hall_id: number) {
  return request({
    url: url.editGameDetailHall,
    method: 'post',
    data: { id, game_id, hall_id }
  }) as unknown as Promise<ResponseFormat<void>>
}

// 修改返水用館別(單筆)
export function editBetHall (id: number, game_id: number, bet_hall: number) {
  return request({
    url: url.editBetHall,
    method: 'post',
    data: { id, game_id, bet_hall }
  }) as unknown as Promise<ResponseFormat<void>>
}

// 遊戲列表同步-单一
export function synchronize (gameType: number) {
  return request({
    url: url.synchronize,
    method: 'post',
    data: { gameType }
  }) as unknown as Promise<ResponseFormat<void>>
}

// 遊戲列表同步-所有
export function synchronizeAll () {
  return request({
    url: url.synchronizeAll,
    method: 'post',
    timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<void>>
}

export function editGamePlatformDetail (data: EditGamePlatformDetailItem[]) {
  return request({
    url: url.editGamePlatformDetail,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}

// 平台維護管理
export function gameMaintenanceView () {
  return request({
    url: url.gameMaintenanceView,
    method: 'get'
    // data: parameter
  }) as unknown as Promise<ResponseFormat<gameMaintenanceViewItem[]>>
}

export function gameMaintenanceEdit (data: EditGameMaintenanceItem[]) {
  return request({
    url: url.gameMaintenanceEdit,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}
export function transferOutAllMem (params: {
  gameID: number
}) {
  return request({
    url: url.transferOutAllMem,
    method: 'get',
    params,
    timeout: Infinity
  }) as unknown as Promise<ResponseFormat<void>>
}
// 遊戲紀錄API
export function gameApiLogGetFn () {
  return request({
    url: url.gameApiLogGetFn,
    method: 'get'
    // data: parameter
  }) as unknown as Promise<ResponseFormat<gameApiLogGetFnItem[]>>
}

export function viewGameApiLog (data: GameApiReq) {
  return request({
    url: url.viewGameApiLog,
    method: 'post',
    data,
    timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<ViewGameApiItem[]>>
}

// 取帳程序
export function gameApiBetPostBet (data: gameApiBetReq) {
  return request({
    url: url.gameApiBetPostBet,
    method: 'post',
    data,
    timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<ResponseFormat<void>>>
}

// 強制重新計算 bet detail + sbet
export function gameApiBetRecalculateBetDetail (data: gameApiBetRecalculateBetDetailReq) {
  return request({
    url: url.gameApiBetRecalculateBetDetail,
    method: 'post',
    data,
    timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<ResponseFormat<void>>>
}

// 重新統計sbet
export function gameApiBetRecalculateSbet (data: gameApiBetRecalculateSbetReq) {
  return request({
    url: url.gameApiBetRecalculateSbet,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<ResponseFormat<void>>>
}

// 平台數據列表
export function gameApiBetView () {
  return request({
    url: url.gameApiBetView,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<gameApiBetViewItem[]>>
}

// 取Icon
export function getIcon () {
  return request({
    url: url.getIcon,
    method: 'get'
    // data: parameter
  }) as unknown as Promise<ResponseFormat<void>>
}

// 分類管理
export interface getListReq {
  page: number,
  limit: number
}

export function getList (params: getListReq) {
  return request({
    url: url.getList,
    method: 'get',
    params
  }) as unknown as Promise<ResponseFormat<Pagination<GetListItem[]>>>
}

// 分類管理-Add
export function gameTabManagementAdd (data: gameTabManagementAddItem) {
  return request({
    url: url.gameTabManagementAdd,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}

// 分類管理-Edit
export function gameTabManagementEdit (data: gameTabManagementEditItem) {
  return request({
    url: url.gameTabManagementEdit,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}

// 分類管理-EditContent
export function gameTabManagementEditContent (data: gameTabManagementEditContentItem) {
  return request({
    url: url.gameTabManagementEditContent,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}

// 分類管理-列表排序
export function gameTabManagementEditSort (tabIds: string[]) {
  return request.post(url.gameTabManagementEditSort, { tabIds }) as unknown as Promise<ResponseFormat<null>>
}

// 分類管理-修改狀態
export function gameTabManagementSetStatus (data: gameTabManagementSetStatusItem) {
  return request({
    url: url.gameTabManagementSetStatus,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<void>>
}

// 分類管理-Del
export function gameTabManagementDel (id: number) {
  return request({
    url: url.gameTabManagementDel,
    method: 'post',
    data: { id }
  }) as unknown as Promise<ResponseFormat<void>>
}

// 取得平台設置清單
export function gameInfoView () {
  return request({
    url: url.gameInfoView,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<{
    // 分類
    title: {[categoryId: string]: MultipleLanguage}
    // 內容
    content: {[categoryId: string]: gameInfoItem[]}
  }>>
}

// 平台設置-編輯
export function gameInfoEdit (data: gameInfoEditItem) {
  return request({
    url: url.gameInfoEdit,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<gameInfoEditItem[]>>
}
