import { render, staticRenderFns } from "./Working.vue?vue&type=template&id=308a21a6&scoped=true&lang=pug&"
var script = {}
import style0 from "./Working.vue?vue&type=style&index=0&id=308a21a6&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308a21a6",
  null
  
)

export default component.exports