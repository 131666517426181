import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6da577a9&lang=pug&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SystemMaintenanceAlert-i18n.json5?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FSystemMaintenanceAlert%2Findex.vue&lang=json5"
if (typeof block0 === 'function') block0(component)

export default component.exports