<!-- 系統訊息列表 -->
<template lang="pug">
v-list(height="568" outlined style='overflow: auto; border-radius: 5px;')
  v-progress-linear(:indeterminate="!!ajaxHandle" background-opacity='0')
  //- 無公告
  v-btn(
    block disabled
    v-if="!ajaxHandle && !items.length"
  ) {{ $t('noData') }}
  //- 列表
  v-list-item-group(v-model="messageId")
    div(v-for="(item, i) in items" :key="i")
      v-list-item
        v-list-item-icon.mx-0
          v-icon mdi-circle-small
        v-list-item-content
          v-list-item-title(v-text="item.title")
      v-divider
</template>

<script type="ts">
import Vue from 'vue'
import * as me from '@/api/me'

export default Vue.extend({
  data () {
    return {
      ajaxHandle: null,
      messageId: null,
      items: []
    }
  },
  methods: {
    getList () {
      this.ajaxHandle = me.getAnnouncement()
        .then(response => {
          this.items = response.data
        }).finally(() => {
          this.ajaxHandle = null
        })
    }
  },
  watch: {
    messageId (index) {
      this.$emit('input', this.items[index])
    }
  }
})
</script>

<i18n lang="json5" src="./list-i18n.json5"></i18n>
