<!-- TODO: 版面待美化 -->
<template lang="pug">
v-dialog(v-model="dialog" content-class='elevation-0')
  DialogBreakpoints(xs='12' sm='8' md='6' max-width='820px')
    v-card
      v-card-title {{ $t('common.myInbox') }}
        v-spacer
        v-icon(@click="close") mdi-close
      v-card-text
        v-card
          v-card-title
            //- 分頁標頭
            v-tabs(v-model="typeIndex")
              v-tabs-slider(color="error")
              v-tab(
                v-for="(item, i) in tabTitle"
                :key="i"
              ) {{ item.name }}
          v-card-text(style="height: 600px; overflow: auto")
            v-tabs-items(v-model="typeIndex")
              //- 公告
              v-tab-item
                announce(ref='announce')
              //- 個人訊息
              v-tab-item()
                inbox(ref='inbox')
</template>

<script>
import Vue from 'vue'
import * as constants from './constants'
import announce from './announce/index.vue'
import inbox from './inbox/index.vue'

export default Vue.extend({
  components: {
    announce,
    inbox
  },
  data () {
    return {
      constants,
      dialog: false,
      typeIndex: 0,
      // 訊息類型
      messageType: constants.ANNOUNCE,
      // 防止操作者頻繁點擊
      inboxThrottle: false,
      announceThrottle: false
    }
  },
  computed: {
    tabTitle () {
      return [
        { id: constants.ANNOUNCE, name: this.$t('modifyLogs.LG_27') },
        { id: constants.INBOX, name: this.$t('common.message') }
      ]
    }
  },
  methods: {
    // 顯示信箱內容
    show () {
      this.dialog = true
      setTimeout(() => {
        // 取得公告資料，個人資料靠 watch 抓取，避免一開始無DOM無法叫用$ref)
        this.$refs.announce.$refs.msgList.getList()
      }, 10)
    },
    close () {
      this.dialog = false
      this.typeIndex = 0
    }
  },
  watch: {
    typeIndex: {
      handler (newVal, oldVal) {
        // 防呆 - 有切換才觸發
        if (newVal === oldVal) return
        // 取公告
        if (newVal === 0) {
          // 防止操作者頻繁點擊 (過3秒才可以重新撈資料)
          if (!this.announceThrottle) {
            this.announceThrottle = true
            setTimeout(() => {
              this.$refs.announce.$refs.msgList.getList()
            }, 10)
            setTimeout(() => {
              this.announceThrottle = false
            }, 3000)
          }
        }
        // 取個人訊息
        if (newVal === 1) {
          // 防止操作者頻繁點擊 (過3秒才可以重新撈資料)
          if (!this.inboxThrottle) {
            this.inboxThrottle = true
            setTimeout(() => {
              this.$refs.inbox.$refs.msgList.getList()
            }, 10)
            setTimeout(() => {
              this.inboxThrottle = false
            }, 3000)
          }
        }
      }
    }
  }
})
</script>
