import { render, staticRenderFns } from "./SavingOverlay.vue?vue&type=template&id=61b7d0f4&scoped=true&lang=pug&"
import script from "./SavingOverlay.vue?vue&type=script&lag=ts&lang=js&"
export * from "./SavingOverlay.vue?vue&type=script&lag=ts&lang=js&"
import style0 from "./SavingOverlay.vue?vue&type=style&index=0&id=61b7d0f4&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b7d0f4",
  null
  
)

/* custom blocks */
import block0 from "./SavingOverlay-i18n.json5?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FSavingOverlay.vue&lang=json5"
if (typeof block0 === 'function') block0(component)

export default component.exports