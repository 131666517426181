<!-- 點數兌換審核通知 -->
<template lang="pug">
v-btn.position-relative(
  v-if="hasPermission"
  depressed fab small
  @click="$refs.inbox.show()"
)
  v-icon mdi-email
  v-chip(
    x-small
    v-if="value"
  ).count.px-0 {{ Math.min(value, 99) }}
  Inbox(ref="inbox")
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import Inbox from '@/views/Inbox/index.vue'
import { getNotifications as getNotificationAPI } from '@/api/me'

// 如果有收到異動，要更新數值
// TODO: 之後這邊可以寫成常數
const EVENT_NAME = 'WithdrawalTransfer/update'

export default Vue.extend({
  components: {
    Inbox,
  },
  props: {
    to: {
      type: String,
      default: 'InboxRecord'
    },
    refreshDuration: {
      type: Number,
      default: 20 * 1000
    }
  },
  data: () => ({
    value: 0,
    timer: null
  }),
  computed: mapState({
    nav: state => state.permission.nav,
    hasPermission () {
      for (const mainMenu of this.nav.filter(item => item.children)) {
        for (const childMenu of mainMenu.children) {
          if (childMenu.to === this.to) {
            return true
          }
        }
      }
      return false
    }
  }),
  watch: {
    hasPermission: {
      immediate: true,
      handler (val) {
        if (val) {
          this.refresh()
        }
      }
    },
    timer (timer, oldTimer) {
      clearTimeout(oldTimer)
    }
  },
  methods: {
    refresh () {
      this.timer = null
      if (!this.hasPermission) {
        return
      }
      getNotificationAPI()
        .then(res => { this.value = res.data })
        .finally(() => {
          if (!this._isDestroyed) {
            this.timer = setTimeout(() => { this.refresh() }, this.refreshDuration)
          }
        })
    }
  },
  mounted () {
    // 如果有收到異動，要更新數值
    this.$store.state.app.eventHub.on(EVENT_NAME, () => {
      this.refresh()
    })
  },
  beforeDestroy () {
    this.timer = null
  }
})
</script>

<style lang="sass" scoped>
.position-relative
  position: relative

.count
  position: absolute
  right: 4px
  bottom: -2px
</style>
