import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import dialog from './modules/dialog'
import user from './modules/user'
import permission from './modules/async-router'
import getters from './getters'
import websocket from './modules/app/websocket'
import windowSize from './modules/windowSize'
import betHallList from './modules/betHallList'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  // plugins: [createPersistedState()], // 自動把資料存到 localStorage
  modules: {
    app,
    dialog,
    /** @ts-ignore */
    user,
    permission,
    websocket,
    windowSize,
    betHallList
  },
  getters
})

export default store
