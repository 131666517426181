import importPages from './import-pages'
import viewportSet from './viewport'
import { RouteConfig } from 'vue-router'
import { Menu } from '@/api/auth'

/**
 * 把選單轉換成 Router 結構
 */
export function generateRouteConfig(serverMenu: Menu.AjaxData) {
  const routes: RouteConfig[] = []
  for (const mainMenu of serverMenu) {
    for (const child of mainMenu.children) {
      routes.push({
        name: child.name,
        path: `/${child.to}`,
        component: importPages[child.to] ?? importPages.Working,
        meta: {
          permissions: child.permissions,
          viewport: 'width=' + viewportSet() + ', height=device-height, initial-scale=1.0'
        }
      })
    }
  }
  return routes
}
