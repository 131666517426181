export default function () {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.indexOf('android') !== -1) {
    // Android device
    return window.outerWidth * 4
  } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
    // iOS device
    return 1920
  } else {
    // neither an Android nor iOS device
  }
}
