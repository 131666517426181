<template lang="pug">
v-main.loginBg
  v-container.fill-height(fluid)
    v-row(align='center' justify='center')
      v-col(cols='12')
        div.logo
          img(src="/storage/logo/loading.png")
      //- 限制表單寬度
      //- 不寫在父元素 v-row 因為不確定 logo 寬度為多少
      //- 可能比例會跑掉
      v-col(style="max-width: 480px")
        v-card.elevation-24
          v-toolbar(color='dialogTitleBg' dark flat)
            v-toolbar-title {{ $t('systemLogin') }}
            .flex-grow-1
          v-card-text.pa-2
            v-tabs-items(v-model='pageId' touchless)
              v-tab-item
                Login(
                  :form='form'
                  :message='message'
                  :processing='ajaxHandle'
                  @login='login'
                )
              v-tab-item
                TwoStepForm(
                  :form='form'
                  :message='message'
                  :processing='ajaxHandle'
                  @login='login'
                  @back='goBack'
                )
</template>

<script>
import Login from './Login.vue'
import TwoStepForm from './twoStepForm.vue'

export default {
  components: {
    Login,
    TwoStepForm
  },
  data () {
    return {
      pageId: 0,
      ajaxHandle: null,
      form: {
        account: '',
        password: '',
        otp: ''
      },
      message: {
        type: null,
        content: ''
      }
    }
  },
  methods: {
    login () {
      if (this.ajaxHandle) {
        return
      }
      this.message.type = null
      this.message.content = ''
      this.ajaxHandle = this.$store
        .dispatch('user/Login', this.form)
        .then((response) => {
          // 帳號需要 2fa 驗證，切換到輸入驗證碼頁面
          if (response.code == 1) {
            this.message.type = 'warning'
            this.message.content = response.message
            this.form.otp = ''
            this.pageId += 1
            throw Error(response.message)
          }
        })
        .then(() => {
          this.message.type = 'success'
          this.message.content = this.$t('loginSuccess')
          // 登入成功，更新路由表
          return Promise.all([
            this.$store.dispatch('user/Whoami'),
            this.$store.dispatch('GenerateRoutes')
          ])
        })
        .then(() => {
          this.$router.replace({ to: '/' })
          setTimeout(() => {
            //  FIXME: 有時候登入不會自動導向到登入後頁面，所以先用這個方法繞過去
            window.location.reload()
          }, 1)
        })
        .catch((err) => {
          this.message.type = 'error'
          if (err.isAxiosError && err.response) {
            if (typeof err.response.data === 'object') {
              this.message.content = err.response.data.message
            } else {
              this.message.content = `${err.response.status} - ${err.response.data}`
            }
          } else {
            this.message.content = err.message
          }
          return Promise.resolve()
        })
        .finally(() => {
          this.ajaxHandle = null
        })
    },
    goBack () {
      this.form.password = ''
      this.form.otp = ''
      this.message.content = ''
      this.pageId -= 1
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development') {
      this.form.account = 's_upervisor'
      this.form.password = 'aaa111'
    }
  }
}
</script>

<style lang="sass">
.loginBg
  background-image: radial-gradient(#f1f1f1, #cbcbcb)
.logo
  text-align: center
  img
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3))
    max-height: 110px
    max-width: calc(480px - 24px)
</style>

<i18n lang="json5" src="./index-i18n.json5"></i18n>
