
import Vue from 'vue'
import io, { Socket } from 'socket.io-client'
import * as request from '@/utils/request'
import { EventEmitter } from 'events'

export default Vue.extend({
  data (): {
    socket: Socket | null
  } {
    return {
      socket: null
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler (loggedIn) {
        if (loggedIn) {
          this.connect()
        } else {
          this.socket && this.socket.disconnect()
        }
      }
    }
  },
  methods: {
    connect () {
      const self = this
      const socketURL = ''
      const socket = io(socketURL)
      this.socket = socket
      // 当连接服务端成功时触发connect默认事件
      socket.on('connect', function () {
        console.log('connect success')

        // 更新 store 的連線狀態
        const mutationName = 'websocket/connected'
        self.$store.commit(mutationName, true)
        socket.on('disconnect', () => { self.$store.commit(mutationName, false) })
      })

      // 錢包餘額更新
      socket.on('balance', function (balance) {
        console.log('balance is', balance)
        self.$store.commit('user/balance', balance)
      })

      // 通知被登出
      socket.on('kickSession', (exitCode) => {
        (request.events as EventEmitter).emit('loginRequired')
        socket.disconnect()
        this.socket = null
      })

      // 線上人數異動
      socket.on('onlineUserCount', (data) => {
        console.log('onlineUserCount', data)
        self.$store.commit('websocket/onlineUserCount', data)
      })
    }
  }
})
