import Vue from 'vue'

/**
 * 字串允許範圍
 * @param strLen 長度字元
 */
export default function strlenRange (this: Vue, strLen: {min: number, max: number}) {
  return (v) => {
    if (!v) {
      return true
    } else if (
      v.toString().length >= strLen.min &&
      v.toString().length <= strLen.max
    ) {
      return true
    }
    return this.$t('validate.strlenRange', strLen)
  }
}
