import request from '@/utils/request'
import ResponseFormat from './response-format'

const url = {
  aboutMe: '/Me/aboutMe',
  changePassword: '/Me/changePassword',
  marquee: '/Me/marquee',
  popup: '/Me/popup',
  announcement: '/Me/announcement',
  securityCode: '/Me/securityCode',
  gameListByClassification: '/Me/gameListByClassification',
  statisticBet: '/Me/statisticBet',
  delNote: '/Me/delNote',
  gameInfo: '/Me/gameInfo',
  statisticAccount: '/Me/statisticAccount',
  domainList: '/Me/domainList', // 網址下拉選單資料
  userClassList: '/Me/userClassList', // 身份別 縮寫列表
  getNotifications: '/Me/getNotifications'
}

/**
 * 我的檔案 - 關於我
 */
export function aboutMe () {
  return request({
    url: url.aboutMe
  }) as unknown as Promise<
    ResponseFormat<{
      account: string,
      create_time: number,
      last_login_time: number,
      last_login_ip: string
    }>
  >
}

/**
 * 我的檔案 - 更新密碼
 * @param parameter 資料
 */
export function changePassword (parameter: {
  old_password: string,
  new_password: string
}) {
  return request({
    url: url.changePassword,
    method: 'post',
    data: parameter
  }) as unknown as Promise<
    ResponseFormat<{
    }>
  >
}

/**
 * 我的檔案 - 重設安全碼
 * @param parameter 資料
 */
export function securityCode (parameter: {
  secret_password: number
  self_secret_password: number
}) {
  return request({
    url: url.securityCode,
    method: 'post',
    data: parameter
  }) as unknown as Promise<
    ResponseFormat<{
    }>
  >
}

/**
 * 跑馬燈
 */
export function marquee () {
  interface MarqueeContent {
    id: number,
    content: string,
  }

  return request({
    url: url.marquee
  }) as unknown as Promise<
    ResponseFormat<MarqueeContent[]>
  >
}

// 系統公告
type CategorySYS = 1
// 管理公告
type CategoryAdmin = 2

/**
 * 彈跳公告
 */
export interface PopupAnnouncementContent {
  id: number,
  category: CategorySYS|CategoryAdmin,
  present_type: number,
  title: string,
  content: string,
  create_time: number
}
export function getPopupAnnouncement () {
  return request({
    url: url.popup
  }) as unknown as Promise<
    ResponseFormat<PopupAnnouncementContent[]>
  >
}

/**
 * 公告
 */
export interface AnnouncementContent {
  id: number,
  category: CategorySYS|CategoryAdmin,
  title: string,
  content: string,
  create_time: number
}
export function getAnnouncement () {
  return request({
    url: url.announcement
  }) as unknown as Promise<
    ResponseFormat<AnnouncementContent[]>
  >
}

/**
 * 報表-每日輸嬴 - 遊戲清單陣列
 */
export interface gameListByClassificationItem {
  data:[]
}
export function gameListByClassification () {
  // 指定查報表用的時候 可查全部 若有其他函式叫用這個函式 只取開放時 請傳1
  const params = { paper_flag: 1 }
  return request({
    url: url.gameListByClassification,
    method: 'get',
    params
  }) as unknown as Promise<ResponseFormat<gameListByClassificationItem[]>>
}

/**
 * 網址下拉選單資料
 */
export interface domainListItem {
  data: []
}
export function domainList () {
  return request({
    url: url.domainList,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<domainListItem[]>>
}

/**
 * 我的檔案 - 統計信息
 */
export interface statisticBetReq {
  start: number,
  end: number
}
export interface statisticBetItem {
  data: {}
}
export function statisticBet (params: statisticBetReq) {
  return request({
    url: url.statisticBet,
    method: 'get',
    params,
    timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<{}>>
}
/**
 * 我的檔案 - 統計下線數量
 */
export interface statisticAccountItem {
  data: []
}
export function statisticAccount () {
  return request({
    url: url.statisticAccount,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<[]>>
}
/**
 * 我的檔案 - 注意事項
 */
export function delNote () {
  return request({
    url: url.delNote,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<{}>>
}

/**
 * 我的檔案 - 遊戲資訊
 */
export function gameInfo () {
  return request({
    url: url.gameInfo,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<{}>>
}

/**
 * 身份別 縮寫列表
 */
export function userClassList () {
  return request({
    url: url.userClassList,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<{[classId: number]: string}>>
}

/**
 * 取得待讀取數量
 */
export function getNotifications () {
  return request({
    url: url.getNotifications
  }) as Promise<ResponseFormat<Number>>
}
