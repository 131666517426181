<!-- 「正在保存」的提示  -->
<template lang="pug">
v-overlay(
  absolute
  :value="value"
  :class="{'saving-overlay__visible': value}"
)
  v-card(light)
    v-card-text.text-center
      v-progress-circular(
        indeterminate
        color="primary"
        style="width: 1rem"
      ).mx-2
      | {{ showMessage }}
</template>

<script lag="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: null,
      default: false
    },
    message: {
      type: [String, null],
      default: null
    }
  },
  computed: {
    showMessage () {
      if (this.message === null) {
        return this.$t('saving')
      } else {
        return this.message
      }
    }
  },
  mounted () {
    this.$el.parentElement.style.position = 'relative'
  }
})
</script>

<style lang="sass" scoped>
.saving-overlay__visible
  // 顯示訊息時，讓使用者無法點擊到底下的元素
  pointer-events: auto
</style>

<i18n lang="json5" src="./SavingOverlay-i18n.json5"></i18n>
