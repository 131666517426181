module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "systemLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Login"])}
      },
      "cn": {
        "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入成功"])},
        "systemLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统登入"])}
      },
      "ms": {
        "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berjaya"])},
        "systemLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk Sistem"])}
      },
      "vi": {
        "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành công"])},
        "systemLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập hệ thống"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"loginSuccess":"Success","systemLogin":"System Login"},"cn":{"loginSuccess":"登入成功","systemLogin":"系统登入"},"ms":{"loginSuccess":"Berjaya","systemLogin":"Log Masuk Sistem"},"vi":{"loginSuccess":"Thành công","systemLogin":"Đăng nhập hệ thống"}}')
  delete Component.options._Ctor
  
}
