import Vue from 'vue'

/**
 * 最少需要選擇幾個項目
 * @param length 選中項目
 */
export default function leastSelectedLength (this: Vue, length: number) {
  return (v: any[]|undefined) => {
    if (!v) {
      return true
    } else if (length > v.length) {
      return this.$t('validate.leastSelectedLength', { length })
    }
    return true
  }
}
