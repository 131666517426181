import request from '@/utils/request'
import ResponseFormat from './response-format'

const url = {
  login: '/Auth/login',
  logout: '/Auth/logout',
  twoStepCode: '/auth/2step-code',
  // get my info
  whoami: '/Auth/whoami',
  userMenu: '/Me/nav'
}

/**
 * 登入
 */
export function login (parameter: {
  account: string
  password: string,
  otp?: string
}) {
  return request({
    url: url.login,
    method: 'post',
    data: parameter
  }) as unknown as Promise<
    ResponseFormat<{
      token: string
    }>
  >
}

export function whoami () {
  return request({
    url: url.whoami,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }) as unknown as Promise<
    ResponseFormat<{
      account: string,
      nickname: string,
      currency: string,
      identity: {
        class_id: number,
        type: string,
        name: string
      },
      isSupervisor: boolean,
      create_ag: boolean
    } | null>
  >
}

export declare namespace Menu {
  type Lang = { [lang: string]: string }
  interface Page {
    name: string,
    permissions: string[],
    lang: Lang,
    to: string
  }
  export interface Menu {
    name: string,
    icon: string,
    lang: Lang,
    children: Page[]
  }
  export type AjaxData = Menu[]
}

export function getCurrentUserNav (token: string) {
  return request({
    url: url.userMenu,
    method: 'get'
  }) as unknown as Promise<
    ResponseFormat<Menu.AjaxData>
  >
}

export function logout () {
  return request({
    url: url.logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
