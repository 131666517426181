export function fn (value) {
  const num = parseFloat(value)
  if (isNaN(num)) {
    return value
  }

  const [integer, decimal] = String(value).split('.')
  const result = [
    parseInt(integer).toLocaleString('en')
  ]
  if (decimal !== undefined) {
    result.push(decimal)
  }

  return result.join('.')
}

export default {
  install (Vue, options) {
    Vue.mixin({
      filters: {
        numberAddComma: fn
      }
    })
  }
}
