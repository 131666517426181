<!-- 訊息內容 -->
<template lang="pug">
v-card(flat outlined height="568" style='overflow: auto')
  template(v-if="message")
  v-card-title
    | {{ message.title }} &nbsp;
    .text-caption {{ unixTimestampFormat(message.create_time, 'YYYY-MM-DD HH:mm') }}
    //- 寄件人
    .caption(v-if="item") {{ item.sender_account }}
  SavingOverlay(:value="ajaxHandle" :message="$t('common.loading')")
  v-card-text.mt-n4
    v-divider.mb-2
    //- div.message-content
    template(v-if="item")
      template(v-if="item.title.includes('Download Export') === true")
        template(v-if="JSON.parse(item.content).expire_time < parseInt(new Date().getTime() / 1000)")
          | {{ $t('button.downloadExpired') }}
        template(v-else)
          a(href='javascript:;' @click='download(JSON.parse(item.content).file)') {{ $t('button.clickDownload') }}
      template(v-else)
        .body-1 {{item.content}}

</template>

<script>
import * as inbox from '@/api/me-inbox'
import saveAs from 'file-saver'

export default {
  props: {
    message: {
      type: [Object, null],
      default: null
    }
  },
  data: () => ({
    item: null,
    ajaxHandle: null
  }),
  watch: {
    message: {
      immediate: true,
      handler (item) {
        this.item = null
        // console.log('ite', item)
        this.ajaxHandle = inbox.getDetail(item.id)
          .then((response) => {
            this.item = response.data
            item.read_time = this.item.read_time
          })
          .finally(() => {
            this.ajaxHandle = null
          })
      }
    }
  },
  methods: {
    download (item) {
      const fileUrl = '/storage/downloadCsv/' + item
      const link = document.createElement('a')
      link.href = fileUrl
      link.click()
      // const blob = await fetch('http://sy.local:7793/storage/downloadCsv').then(res => res.blob())
      // saveAs(blob, item)
      // const blob = await fetch('http://sy.local:7793/storage/downloadCsv').then(res => res.blob())
      // content = window.URL.createObjectURL(blob)
      // const a = document.createElement('a')
      // a.href = content
      // a.download = item
      // document.body.appendChild(a) // 兼容火狐
      // a.click()
      // document.body.removeChild(a) // 移除a标签
      // window.URL.revokeObjectURL(content)  // 释放content对象
    }
  }
}
</script>

<style lang="sass" scoped>
.message-content
  min-height: 100px
</style>
