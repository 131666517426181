import Vue from 'vue'

/**
 * 必填欄位 主要用在下拉選單v==0 但不可為0的情況
 */
export default function required (this: Vue, v) {
  return (
    v !== '' &&
    v !== null &&
    v !== undefined &&
    v !== 0
  ) || this.$t('validate.requiredS')
}
