import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import * as authAPI from '@/api/auth'
import * as UserInfo from '@/api/user-info'
import { ACCESS_TOKEN } from '@/constants'

storage.addPlugin(expirePlugin)

export interface State {
  token: string
  account: string | null
  nickname: string | null
  currency: string | null
  identity: null | { class_id: number, type: string, name: string }
  isLoggedIn: boolean | null
  balance: number | null
  isSupervisor: boolean | null
  create_ag: boolean | null
}

const state: State = {
  token: storage.get(ACCESS_TOKEN),
  account: null,
  nickname: null,
  currency: null,
  identity: null,
  isLoggedIn: null,
  balance: 0,
  isSupervisor: null,
  create_ag: null
}

const user = {
  namespaced: true,
  state,
  mutations: {
    SET_TOKEN: (state, token) => {
      storage.set(ACCESS_TOKEN, token)
      state.token = token
    },
    balance: (state, value) => {
      state.balance = parseFloat(value)
    }
  },

  actions: {
    // 登录
    Login ({ commit, state }, userInfo) {
      return authAPI.login(userInfo)
        .then(response => {
          // http 拋 200，但 code 有可能為非 0
          // (ex: 需要二階段認證)
          if (response.code === 0) {
            const result = response.data
            commit('SET_TOKEN', result.token)
            state.isLoggedIn = true
          }
          return response
        })
    },

    // 获取用户信息
    async Whoami ({ commit, state }) {
      const res = await authAPI.whoami()
      const result = res.data
      if (result) {
        state.account = result.account
        state.nickname = result.nickname
        state.currency = result.currency
        state.identity = result.identity
        state.isLoggedIn = true
        state.isSupervisor = result.isSupervisor
        state.create_ag = result.create_ag
      } else {
        state.isLoggedIn = false
      }
      return result
    },

    // 獲取 用戶額度金額
    async Wallet ({ commit, state, rootState }) {
      // 如果 webSocket 有連線的話，錢包資料就由 ws 提供
      // 沒有的話再用 ajax 取資料做 fallback
      if (rootState.websocket.connected) {
        return
      }

      const res = await UserInfo.wallet()
      const result = res.data
      if (result) {
        state.balance = result.amount
      }
    },

    // 登出
    Logout ({ commit, dispatch, store, state }): Promise<void> {
      return authAPI.logout()
        .then(() => {
          commit('SET_TOKEN', '')
          state.account = null
          state.nickname = null
          state.identity = null
          state.isLoggedIn = false
          state.create_ag = null
        })
        .catch((err) => {
          console.log('logout fail:', err)
          // resolve()
        })
        .finally(() => {
          dispatch('ResetRouter', null, { root: true })
        })
    }
  }
}

export default user
