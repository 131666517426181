import Vue from 'vue'

/**
 * 比較大小 ( "結束日期(inputValue)" 不可小於等於 "起始日期(referValue)" )
 */
export default function numThanSize(this: Vue, referValue) {
  return (inputValue) => {
    // console.log('inputValue', inputValue)
    // console.log('referValue', referValue)

    if (!inputValue) {
      return true
    } else if (this.getMoment(inputValue).unix() <= this.getMoment(referValue).unix()) {
      return this.$t('validate.numThanSize', { referValue }) // 不可小於等於...
    }
    return true
  }
}
