import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
import i18n from '@/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => (i18n.t(key, params) as string)
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: colors.orange.darken2,
        secondary: '#fe6f28',
        accent: colors.shades.black,
        error: colors.deepOrange.darken4,
        info: '#009EF6',
        success: colors.lightGreen.lighten1,
        warning: '#E89B26',
        button: '#e49732',
        addBtn: colors.orange.darken2,
        cancel: '#C4C4C4',
        confirm: colors.orange.darken2,
        search: '#EEE',
        focus: colors.orange.accent4,
        enable: '#cae9e6',
        deactivate: '#e7e7e7',
        alertMsgBg: '#f4e2e2', // 警告訊息背景
        alertMsgTitle: '#b10128', // 警告訊息標題
        alertMsgText: '#222', // 警告訊息內文
        appbarIcon: '#666666',
        checked: colors.lightGreen.darken2,
        dialogTitleBg: colors.lightGreen.darken2
      },
      dark: {
        primary: colors.blue.lighten3
      }
    }
  }
})
