import Vue from 'vue'

/**
 * 頭尾及中間，不可有空格
 */
// export default function noSpaces (this: Vue, v) {
//     const pattern = /\s/g
//     const xx = pattern.test(v)
//     if (xx) {
//       return '不可有空格'
//     } else if (!xx) {
//       return true
//     }
//     return true
// }

// export default function noSpaces (this: Vue) {
//   return (v) => {
//     const pattern = /\s/g
//     const xx = pattern.test(v)
//     if (xx) {
//       return '不可有空格'
//     } else if (!xx) {
//       return true
//     }
//     return true
//   }
// }

export default function noSpaces (this: Vue, v) {
  return /\s/g.test(v) === false || this.$t('validate.noSpaces')
}
