import store from '@/store'

/**
 * 後台回傳多語言欄位的解析方法
 */
export default {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        /**
         * 轉換多語系
         */
        multipleLang () {
          return (langFormat: {[locale: string]: string}) => {
            const lang = store.getters.lang
            return langFormat[lang]
          }
        },
        /**
         * 轉換多語系(娶某個特定欄位)
         */
        multipleLangItem () {
          return (index: string) => {
            return (item: any) => {
              const lang = store.getters.lang
              return item[index][lang]
            }
          }
        }
      }
    })
  }
}
