module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Maintenance."])}
      },
      "cn": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统维护中"])}
      },
      "ms": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyelenggaraan sistem"])}
      },
      "vi": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảo trì hệ thống."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"text":"System Maintenance."},"cn":{"text":"系统维护中"},"ms":{"text":"Penyelenggaraan sistem"},"vi":{"text":"Bảo trì hệ thống."}}')
  delete Component.options._Ctor
  
}
