<!-- 訊息內容 -->
<template lang="pug">
v-card(flat outlined height="568" style='overflow: auto')
  template(v-if="message")
  v-card-title
    span {{message.title}}
  v-card-text
    v-divider.mb-2
    | {{message.content}}

</template>

<script>
export default {
  props: {
    message: {
      type: [Object, null],
      default: null
    }
  }
}
</script>
