import Vue from 'vue'

/**
 * 字串允許範圍
 * @param range 長度字元
 */
export default function numRange (this: Vue, range: {min: number, max: number}) {
  return (v) => {
    if (!v) {
      return true
    } else if (
      v >= range.min && v <= range.max
    ) {
      return true
    }
    return this.$t('validate.numRange', range)
  }
}
