<template lang="pug">
v-card.elevation-0
  v-card-title.mt-n2 Input 2FA
  v-card-text.relative
    v-otp-input(
      ref="otp"
      length="6"
      v-model="form.otp"
      @finish="save"
    )
    v-overlay(absolute v-if="processing")
      v-card(light)
        v-card-text
          v-progress-circular(
            indeterminate
            color="primary"
            style="width: 1rem"
          ).mr-2
          | {{ this.$t('form.progressing') }}
    v-alert.mb-n2(
      v-if="message.content"
      :color="message.type" dense outlined
    ) {{message.content}}
  v-card-actions
    v-spacer
    v-btn(@click="$emit('back')" :disabled="!!processing") {{$t('button.back')}}
    v-btn(color="primary" @click="save" :disabled="disable") {{$t('button.login')}}
</template>

<script>

export default {
  props: ['form', 'message', 'processing'],
  methods: {
    save () {
      this.$emit('login')
    }
  },
  computed: {
    disable () {
      return !!this.processing || this.form.otp.length < 6
    }
  },
  mounted () {
    this.$refs.otp.focus()
  }
}
</script>
