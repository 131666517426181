<!-- 剪貼簿 -->
<template lang="pug">
span
  slot(:copy="copy" :success="success" :error="error")
  //- 複製結果 (顯示成功 or 失敗)
  v-snackbar.py-2(
    :color='color'
    outlined
    v-model='snackbar'
    :timeout='timeout'
    centered
    elevation='0'
  )
    v-icon.mr-3(:color='color') {{ hasError ? "mdi-alert" : "mdi-check-bold" }}
    span {{ copyTooltip }}
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    // 複製的值
    value: { type: null, default: '' },
    // 成功訊息
    successMsg: { type: String, default: '' },
    // 失敗訊息
    errorMsg: { type: String, default: '' },
    // 延遲時間
    timeout: { type: Number, default: 600 }
  },
  created () {
    this.internal_successMsg = this.successMsg !== '' ? this.successMsg : this.multipleLang_success
    this.internal_errMsg = this.errorMsg !== '' ? this.errorMsg : this.multipleLang_err
  },
  data () {
    return {
    internal_successMsg: '',
    internal_errMsg: '',
    multipleLang_success: this.$t('copied'),
    multipleLang_err: this.$t('browserNotSupport'),
    snackbar: false,
    hasError: false,
    copyTooltip: 'Copied to Clipboard'
    }
  },
  computed: {
    copy () { return this.value },
    color () { return this.hasError ? 'error' : 'success' }
  },
  methods: {
    success () {
      this.hasError = false
      this.copyTooltip = this.internal_successMsg
      this.snackbar = true
    },
    error (e) {
      // 不支持复制
      console.error(e)
      this.hasError = true
      this.copyTooltip = this.internal_errMsg
      this.snackbar = true
    }
  }
})
</script>

<i18n lang="json5" src="./Clipboard-i18n.json5"></i18n>
