import { RouteConfig } from 'vue-router'
import importPages from '@/router/import-pages'
import viewportSet from './viewport'

/** 404 預設參數 */
export const notFound = {
  path: '*',
  name: 'notFound',
  component: importPages.Exception404,
  meta: {
    hidden: true,
    viewport: 'width=' + viewportSet() + ', height=device-height, initial-scale=1.0'
  }
}

/**
 * 登入系統前，預設要載入哪些頁面
 * (越少越好)
 */
const defaultRoute: RouteConfig[] = [
  {
    path: '/Login',
    name: 'login',
    component: importPages.Login,
    meta: {
      hidden: true,
      viewport: 'width=device-width, initial-scale=1.0'
    }
  },
  {
    path: '/404',
    component: importPages.Exception404,
    meta: {
      viewport: 'width=device-width, initial-scale=1.0'
    }
  },
  notFound
]

export default defaultRoute

/**
 * 登入後基本會有的畫面
 */
const defaultLoggedInRoute: RouteConfig[] = [
  {
    path: '/Home',
    name: 'home',
    component: importPages.Home,
    meta: {
      viewport: 'width=' + viewportSet() + ', height=device-height, initial-scale=1.0'
    }
  }
]

export { defaultLoggedInRoute }
