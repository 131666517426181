import Vue from 'vue'

/**
 * 如果相等就報錯 ( "輸入值(inputValue)" 等於 "基準值(referValue)" )
 */
export default function noRepeatedSettings (this: Vue, referValueArr: []) {
  return (inputValue) => {
    if (!inputValue) {
      return true
    } else if (referValueArr.length === 0) {
      return true
    } else if (referValueArr.some((x) => x === Number(inputValue))) {
      return this.$t('validate.handgErr')
    }
    return true
  }
}
