
import Vue from 'vue'
import MountDialog from '@/components/Dialog/Mount.vue'
import NavigatorVue from './components/Navigator.vue'
import AppBarVue from './components/AppBar/index.vue'
import InitializeVue from '@/views/Initialize.vue'
import websocketConnection from '@/components/websocketConnection.vue'
import DemoWatermark from '@/components/DemoWatermark.vue'
import SystemMaintenanceAlert from './components/SystemMaintenanceAlert/index.vue'
import * as request from '@/utils/request'
import PageLoading from '@/components/PageLoading.vue'

const LogoutNoticeDialog = () => import('@/components/LogoutNoticeDialog.vue')
const AnnouncePopup = () => import('@/views/AnnouncePopup.vue')

export default Vue.extend({
  components: {
    MountDialog,
    initialize: InitializeVue,
    navigator: NavigatorVue,
    appBar: AppBarVue,
    LogoutNoticeDialog,
    AnnouncePopup,
    websocketConnection,
    DemoWatermark,
    SystemMaintenanceAlert,
    PageLoading
  },
  data: () => ({
    drawer: false
  }),
  methods: {
    loadStart () {
      console.log('start load')
    },
    loadEnd () {
      console.log('load end')
    },
    // 系統維護
    bindServerMaintenanceEvent () {
      const handleResponse = (response) => {
        // @ts-ignore
        this.$store.commit(
          'app/systemMaintenance',
          response.headers['x-server-maintenance'] > 0
        )
      }
      request.events.on('response', handleResponse)
      request.events.on('error', (err) => handleResponse(err.response))
    }
  },
  mounted () {
    // @ts-ignore
    window.app = this
    // @ts-ignore
    this.bindServerMaintenanceEvent()
  }
})
