import { VueConstructor } from 'vue'
import PageLayout from '@/components/PageLayout.vue'
import SavingOverlay from '@/components/SavingOverlay.vue'
import DialogBreakpoints from '@/components/DialogBreakpoints.vue'
import Cash from '@/components/cash.vue'

/**
 * 系統預設要載入哪些組件
 * @param Vue
 */
export default function (Vue: VueConstructor) {
  Vue.component('PageLayout', PageLayout)
  Vue.component('SavingOverlay', SavingOverlay)
  Vue.component('DialogBreakpoints', DialogBreakpoints)
  Vue.component('Cash', Cash)
}
