import router from './router'
import store from './store'
import { defaultLoggedInRoute, notFound } from './router/default-route'
import sleep from 'await-sleep'

const allowList = ['login'] // no redirect allowList
const loginRoutePath = '/Login'
const defaultRouteName = defaultLoggedInRoute[0].name

router.beforeEach(async (to, from, next): Promise<void> => {
  loadStart()

  // 這邊加上延遲，避免 Router 跑無窮迴圈
  await sleep(16)

  // to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(to.meta.title))
  /* has token */

  // 第一次進入，先初始化資料 (檢查使用者資料那些的)
  if (!store.state.app.initialized) {
    await store.dispatch('app/initialize')
  }

  // @ts-ignore
  if (store.state.user.isLoggedIn) {
    if (to.path.replace(/^\/+/, '/').toLowerCase() === loginRoutePath.toLowerCase()) {
      // 已登入卻又開啟登入頁，導向到 OP 頁
      return next({ name: defaultRouteName, replace: true })
    }

    const redirect: string|null = decodeURIComponent(from.query.redirect as string)
    // 请求带有 redirect 重定向时，登录自动重定向到该地址
    if (from.query.redirect && to.path === redirect) {
      // set the replace: true so the navigation will not leave a history record
      // @ts-ignore
      return next({ path: redirect, replace: true })
    } else if (to.path === '/') {
      return next({ name: defaultRouteName, replace: true })
    }

    /**
     * 如果用戶在需要登入的頁面下重整，會因為 Router 還沒更新導致判斷為 404
     * 所以要額外判斷是否在清單內，有的話就重新執行路由-顯示畫面
     */
    if (to.name === notFound.name) {
      const foundPath = router.getRoutes().find(r => r.path === to.path)
      if (foundPath) {
        return next({ path: to.path, query: to.query })
      }
    }

    // 跳转到目的路由
    return next()
  } else {
    if (allowList.includes(to.name!)) {
      // 在免登录名单，直接进入
      return next()
    }

    return next({
      path: loginRoutePath,
      replace: true,
      query: { redirect: to.fullPath }
    })
  }
})

router.afterEach(() => {
  loadEnd()
})

function loadStart () {
  store.dispatch('app/loading')
}

function loadEnd () {
  store.dispatch('app/loadEnd')
}
