import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import * as request from './utils/request'

import './permission' // permission control
import i18n from './i18n'

import useDefaultComponents from './use-default-components'
import { install } from './router/testPages' // 字串複製
import unixTimestamp from './utils/unixTimestamp'
import multipleLang from './utils/multipleLang'
import makeValidator from './makeValidator'
import VueClipboard from 'vue-clipboard2'
import Dialog from './components/Dialog'
import vueDebounce from 'vue-debounce'
import showIfRouteMetaHasPermission from './utils/showIfRouteMetaHasPermission'
import numberAddComma from './utils/numberAddComma'

Vue.config.productionTip = false
Vue.use(request.VueAxios)
Vue.use(unixTimestamp)
Vue.use(multipleLang)
Vue.use(makeValidator)
Vue.use(showIfRouteMetaHasPermission)
Vue.use(numberAddComma)

// 剪貼簿功能看之後能不能用非同步載入，因為不是所有頁面都會用到
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(Dialog)
Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup'],
  defaultTime: '300ms'
})

useDefaultComponents(Vue)

// @ts-ignore
request.setI18n(i18n)

// 注入測試資料
install()

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
