import { render, staticRenderFns } from "./list.vue?vue&type=template&id=405c59a3&lang=pug&"
import script from "./list.vue?vue&type=script&lang=js&"
export * from "./list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./list-i18n.json5?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FInbox%2Fannounce%2Flist.vue&lang=json5"
if (typeof block0 === 'function') block0(component)

export default component.exports