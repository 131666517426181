import * as typings from './typings'
import { ICON, IMGTYPE } from './constants'
import Vue from 'vue'

/**
 * Dialog 註冊功能
 * TODO: 這邊改用 mutations 去做或比較好
 */
export default {
  install (Vue, options) {
    Vue.mixin({
      methods: {
        $dialog (this: Vue, item: typings.DialogData = {}) {
          const key = Date.now()
          this.$set(this.$store.state.dialog.dialog, key, item)
        },
        /**
         * 成功視窗
         * @param data
         */
        $dialogSuccess (this: Vue, item: typings.DialogSuccessData = {}) {
          const key = Date.now()
          const data = {
            ...item,
            imgOpen: false,
            icon: ICON.ICON_OK,
            buttons: [
              {
                text: this.$t('button.ok'),
                color: 'confirm',
                callback (dialog) {
                  if (item.okCallback) {
                    item.okCallback(dialog)
                  } else {
                    dialog.close()
                  }
                }
              }
            ]
          }
          this.$set(this.$store.state.dialog.dialog, key, data)
        },

        /**
         * 確認視窗
         * @param data
         */
        $dialogConfirm (this: Vue, item: typings.DialogConfirmData = {}) {
          const key = Date.now()
          const data = {
            ...item,
            imgOpen: false,
            icon: ICON.ICON_WARN,
            buttons: [
              {
                text: this.$t('button.cancel'),
                color: 'cancel',
                callback (dialog) {
                  if (item.cancelCallback) {
                    item.cancelCallback(dialog)
                  } else {
                    dialog.close()
                  }
                }
              },
              {
                text: this.$t('button.ok'),
                color: 'confirm',
                callback (dialog) {
                  if (item.okCallback) {
                    item.okCallback(dialog)
                  } else {
                    dialog.close()
                  }
                }
              }
            ]
          }
          this.$set(this.$store.state.dialog.dialog, key, data)
        },

        /**
         * 錯誤視窗
         * @param data
         */
        $dialogError (this: Vue, item: typings.DialogErrorData = {}) {
          const key = Date.now()
          const data = {
            ...item,
            imgOpen: false,
            icon: ICON.ICON_ERROR,
            buttons: [
              {
                text: this.$t('button.ok'),
                color: 'confirm',
                callback (dialog) {
                  if (item.okCallback) {
                    item.okCallback(dialog)
                  } else {
                    dialog.close()
                  }
                }
              }
            ]
          }
          this.$set(this.$store.state.dialog.dialog, key, data)
        },

        /**
         * 圖文視窗
         * @param data
         */
        $dialogOther (this: Vue, item: typings.DialogOtherData = {}) {
          const key = Date.now()
          const data = {
            ...item,
            imgOpen: true,
            buttons: [
              {
                text: this.$t('button.ok'),
                color: 'confirm',
                callback (dialog) {
                  if (item.okCallback) {
                    item.okCallback(dialog)
                  } else {
                    dialog.close()
                  }
                }
              }
            ]
          }
          this.$set(this.$store.state.dialog.dialog, key, data)
        }
      }
    })
  }
}
