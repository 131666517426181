<template lang='pug'>
MarqueeText.marquee.ml-2(
  :duration='marqueeDuration'
  :repeat='2'
  :paused='isPaused'
  @mouseenter='isPaused = !isPaused'
  @mouseleave='isPaused = false'
)
  span.msg(ref='msg' v-for='item in marqueeList')
    img(src='@/assets/images/icons/sound.png')
    span {{ item }}
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import { marquee as getNotificationAPI } from '@/api/me'

export default {
  props: {
    refreshDuration: {
      type: Number,
      default: 20 * 1000
    }
  },
  components: {
    MarqueeText
  },
  data () {
    return {
      isPaused: false,
      marqueeList: [],
      marqueeDuration: 55
    }
  },
  methods: {
    refresh () {
      this.timer = null
      getNotificationAPI()
        .then(res => { this.marqueeList = res.data.map(record => record.content) })
        .finally(() => {
          if (!this._isDestroyed) {
            this.timer = setTimeout(() => { this.refresh() }, this.refreshDuration)
          }
        })
    },
    getWidth () {
      let totalMsgWidth = 0
      // const msgArr: any[] = this.$refs.msg
      const msgArr = this.$refs.msg

      if (msgArr !== undefined) {
        msgArr.forEach((item) => {
          totalMsgWidth += item.offsetWidth
        })
        this.marqueeDuration = totalMsgWidth / 200 * 2
      }
    }
  },
  computed: {
    lang () { return this.$store.state.app.lang }
  },
  watch: {
    // 語系變更時，重新拉取跑馬燈內容
    lang (val) {
      this.refresh()
    },
    // 計算跑馬燈內容寬度
    marqueeList () {
      this.$nextTick(() => this.getWidth())
    }
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style lang='sass' scoped>
.marquee
  max-width: 100%
  height: 100%
  display: inline-flex
  flex-direction: column
  justify-content: center
  position: absolute
  right: 0
  color: #FFF
  .msg
    margin-left: 100px
    img
      display: inline-block
      width: 14px
      height: 14px
      margin-right: 11px
</style>
