module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])}
      },
      "cn": {
        "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在保存"])}
      },
      "ms": {
        "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])}
      },
      "vi": {
        "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiết kiệm"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"saving":"Saving"},"cn":{"saving":"正在保存"},"ms":{"saving":"Saving"},"vi":{"saving":"Tiết kiệm"}}')
  delete Component.options._Ctor
  
}
