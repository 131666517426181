module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "messagePageLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page loading error, please reload page."])}
      },
      "cn": {
        "messagePageLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页面加载错误，请重新加载页面。"])}
      },
      "ms": {
        "messagePageLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ralat pemuatan halaman, sila muat semula halaman."])}
      },
      "vi": {
        "messagePageLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi tải trang, vui lòng tải lại trang."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"messagePageLoadingError":"Page loading error, please reload page."},"cn":{"messagePageLoadingError":"页面加载错误，请重新加载页面。"},"ms":{"messagePageLoadingError":"Ralat pemuatan halaman, sila muat semula halaman."},"vi":{"messagePageLoadingError":"Lỗi tải trang, vui lòng tải lại trang."}}')
  delete Component.options._Ctor
  
}
