import Vue from 'vue'

/**
 * 比對密碼
 * 由於 validator 無法取得外部變數，所以必須要用 callable 的方式來判斷資料是否通過驗證
 * @param checkFn 比對的函數
 */
export default function confirmPassword (this: Vue, checkFn: CallableFunction) {
  return (v) => {
    return checkFn() || this.$t('validate.pswMatch')
  }
}
