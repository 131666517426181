<template lang="pug">
v-list#navigator
  v-list-item(link to="Home")
    v-list-item-icon
      v-icon mdi-home
    v-list-item-content
      v-list-item-title Home
  template(v-for='item in nav')
    //- 沒有下級
    v-list-item(v-if="!item.children" link :to="{ path: item.to }")
      v-list-item-icon
        v-icon {{ item.icon }}
      v-list-item-content
        v-list-item-title(v-text='$t(`nav.${item.name}`)')
    //- 有下級
    v-list-group(
      v-else
      :key='item.name'
      :prepend-icon='"mdi-" + item.icon'
      active-class='active'
      no-action
    )
      template(v-slot:activator)
        v-list-item-content
          v-list-item-title(v-text='$t(`nav.${item.name}`)')
      v-list-item(
        v-for='children in item.children'
        :key='children.name'
        link
        :to="{ path: children.to }"
        active-class='itemActive'
      )
        v-list-item-icon
          v-icon(v-text='children.icon')
        v-list-item-content
          v-list-item-title(v-text='$t(`nav.${children.name}`)')
</template>

<script lag="ts">
export default {
  computed: {
    nav () {
      return this.$store.state.permission.nav
    }
  }
}
</script>

<style lang="sass" scoped>
#navigator :deep
  color: #EEE
  background: #222
  .theme--light.v-list-item--active
    background: #38312d
    background: #1c1815
    &::before
      opacity: 0
  .theme--light.v-list-item, .v-list-item--active
    border-bottom: 1px solid #343130
    &:hover::before
      background: #fff
      opacity: 0.1
  .theme--light.v-icon
    color: #a5a5a5
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    color: #b1b1b1
  .active
    color: #EEE
    background: #3a3a3a
    background: #38312d
    background: #1c1815
  .itemActive
    color: #EEE
    background: #363636 !important
</style>
