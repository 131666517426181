import i18n from '@/i18n'

/**
 * 撈出所有語系的某個 key 值
 * ex: this.$store.getters.dumpLangKey('common.selectAll')
 */
export default function dumpLangKey (state) {
  return function (langKey, values = undefined) {
    return state.app.languages.reduce((acc, cur) => {
      acc[cur] = i18n.t(langKey, cur, values)
      return acc
    }, {})
  }
}
