<!-- 帳號密碼輸入視窗 -->
<template lang='pug'>
v-card.elevation-0
  v-card-text.pb-0
    v-form
      v-text-field(
        :label="$t('form.account')",
        v-model.trim='form.account',
        prepend-icon='mdi-account',
        type='text'
        color='focus'
        autofocus
      )
      v-text-field(
        :label="$t('form.password')",
        v-model.trim='form.password',
        prepend-icon='mdi-key',
        type='password'
        color='focus'
        :type='showPassword ? "text" : "password"'
        :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
        @click:append='showPassword = !showPassword'
        @keyup.enter="!processing && $emit('login')"
      )
    v-select(
      v-model='setLang'
      :items='languages'
      :label="$t('language.language')"
      prepend-icon='mdi-earth'
      color='focus'
      :menu-props='{ bottom: true, offsetY: true }'
      hide-details
    )
    //- 狀態欄
    v-alert.ma-0.ml-8.mt-4(
      v-if='processing'
      dense
      text
      outlined
      type='info'
    ) {{ this.$t('form.progressing') }}
    //- 錯誤訊息
    v-alert.ma-0.ml-8.mt-4(
      v-else-if='message.content'
      dense
      text
      outlined
      :type='message.type'
    ) {{ message.content }}
  v-card-actions.mr-2.mt-1.pb-3
    v-spacer
    v-btn.pa-0(
      text
      x-large
      color='dialogTitleBg'
      @click="$emit('login')"
      :disabled='!!processing'
    ) {{ $t('button.login') }}
</template>

<script>
export default {
  props: ['form', 'message', 'processing'],
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    setLang: {
      get () { return this.$store.state.app.lang },
      set (val) { this.$store.commit('app/lang', val) }
    },
    languages () {
      return this.$store.state.app.languages.map(value => ({
        value,
        text: this.$t(`language.${value.toUpperCase()}`)
      }))
    }
  }
}
</script>
