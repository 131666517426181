import Vue from 'vue'

/**
 * 檢測帳號是否存在
 */
export default function existAccount (this: Vue, returnData: { isExist: boolean, msg: string }) {
  return () => {
    if (!returnData.isExist) {
      return returnData.msg
    }
    return true
  }
}
