import axios from 'axios'
import store from '@/store'
import { VueAxios } from '../axios'
import { EventEmitter } from 'events'

const events = new EventEmitter()

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
})

// eslint-disable-next-line prefer-const
let i18n = null
/**
 * 設定翻譯函數
 */
function setI18n (translator) {
  i18n = translator
}

// 异常拦截处理器
const errorHandler = (error) => {
  error.isServerMaintenance = false
  error.isAuthorizationRequiredError = false
  if (error.response) {
    const contentType = error.response.headers['content-type']
    const responseDataIsJSON = contentType && contentType.indexOf('application/json') !== -1

    const data = error.response.data

    // 是否為伺服器維護
    if (error.response.headers['x-server-maintenance'] > 0) {
      error.isServerMaintenance = true
    }

    events.emit('error', error)

    // 被登出的處理邏輯
    if (
      error.response.status === 401 &&
      !(data.data && data.data.isLogin)
    ) {
      error.isAuthorizationRequiredError = true

      let handle = events.rawListeners('loginRequired')
      if (!handle.length) {
        // 如果沒有監聽任何事件，就塞一個預設的
        // 避免 Request 死掉了，但會員不知道
        handle = [() => alert('Authorization verification failed.')]
      }

      // 執行事件，並等待事件處理完成
      // (ex: 跳出通知視窗，等使用者按下確認之類的)
      const wait = handle.map(fn => fn())
      Promise.all(wait).finally(() => {
        store.dispatch('user/Logout')
          .then(() => {
            setTimeout(() => { window.location.reload() }, 1)
          })
      })
    }
  }

  // 把 config timeout 獨立一個 code 避免混淆
  if (error.code === 'ECONNABORTED' && error.message.match(/^timeout/)) {
    error.code = 'ETIMEDOUT_CONFIG'
  }

  if (i18n) {
    const key = `request.${error.code}`
    const translate = i18n.t(key, error)
    if (translate !== '' && translate !== key) {
      error.message = i18n.t(key, error)
    } else {
      error.message = i18n.t('request.E_OTHER', error)
    }
  }

  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = store.getters.token
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['x-token'] = token
  }
  config.headers['Accept-Language'] = {
    en: 'en-US',
    cn: 'zh-CN',
    ms: 'ms-MY',
    vi: 'vi-VN'
  }[store.state.app.lang]

  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  // 避免回傳 primitive value 導致無法 set 資料
  if (typeof response.data === 'string') {
    response.data = new String(response.data) // eslint-disable-line
  } else if (typeof response.data === 'number') {
    response.data = new Number(response.data) // eslint-disable-line
  }

  Object.defineProperty(response.data, '_raw', {
    value: response,
    enumerable: false
  })

  events.emit('response', response)

  return response.data
}, errorHandler)

/** @var Map<Promise, AbortController> */
const abortHandler = new Map()

/**
 * 新增「取消 Handle」
 */
export function addAbortHandle (promise, abortController) {
  abortHandler.set(promise, abortController)
  promise.finally(() => abortHandler.delete(promise))
}

/**
 * 取消 Ajax
 */
export function abort (promise) {
  const handle = abortHandler.get(promise)
  if (handle) {
    handle.abort()
    return true
  }
  return false
}

/**
 * 取資料
 * @param {Object} reqData
 * @returns
 */
export function req (reqData) {
  const abortController = new AbortController()
  reqData.signal = abortController.signal
  const promise = request(reqData)
  addAbortHandle(promise, abortController)
  return promise
}

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

window.axios = axios
export {
  installer as VueAxios,
  request as axios,
  events,
  setI18n
}
