module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to Clipboard."])},
        "browserNotSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The web browser cannot access clipboard."])}
      },
      "cn": {
        "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制至剪贴簿"])},
        "browserNotSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该浏览器不支持复制"])}
      },
      "ms": {
        "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disalin ke Papan Klip."])},
        "browserNotSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelayar web tidak boleh mengakses papan keratan."])}
      },
      "vi": {
        "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép vào Clipboard."])},
        "browserNotSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trình duyệt web không thể truy cập clipboard."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"copied":"Copied to Clipboard.","browserNotSupport":"The web browser cannot access clipboard."},"cn":{"copied":"已复制至剪贴簿","browserNotSupport":"该浏览器不支持复制"},"ms":{"copied":"Disalin ke Papan Klip.","browserNotSupport":"Pelayar web tidak boleh mengakses papan keratan."},"vi":{"copied":"Đã sao chép vào Clipboard.","browserNotSupport":"Trình duyệt web không thể truy cập clipboard."}}')
  delete Component.options._Ctor
  
}
