import dumpLangKey from './getters/dumpLangKey'

const getters = {
  lang: state => state.app.lang,
  timezone: state => state.app.timezone,
  token: state => state.user.token,
  nickname: state => state.user.nickname,
  roleName: state => state.user.identity?.type,
  userInfo: state => state.user.info,
  addRouters: state => state.permission.addRouters,
  dumpLangKey,
  account: state => state.user.account
}

export default getters
