
import Vue from 'vue'
export default Vue.extend({
  computed: {
    languages () {
      return this.$store.state.app.languages.map(value => ({
        value,
        text: this.$t(`language.${value}`)
      }))
    },
    lang: {
      get () { return this.$store.state.app.lang },
      set (val) { this.$store.commit('app/lang', val) }
    }
  }
})
