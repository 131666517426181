<!-- 系統公告 -->
<!-- TODO: 版面待美化 -->
<template lang="pug">
v-row(no-gutters)
  v-col(cols="3")
    msgList(ref='msgList' v-model="selectedMessage")
  v-col(cols="9")
    //- 訊息內容
    msgContent(
      v-if="selectedMessage"
      :message="selectedMessage"
    )
    v-card(flat outlined height='100%' v-else)
</template>

<script>
import Vue from 'vue'
import msgList from './list.vue'
import msgContent from './message.vue'

export default Vue.extend({
  components: {
    msgList, msgContent
  },
  data: () => ({
    selectedMessage: null
  })
})
</script>
