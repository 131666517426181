// 返水用館別列表資料
import { getListBetHall } from '../../api/game'

export interface State {
  items: Array<Object>
}

const state: State = {
  items: []
}

const betHallList = {
  namespaced: true,
  state,
  getters: {
    /**
     * 取得反水館別陣列
     * @return Array
     */
    items (state) {
      if (!state.items) {
        return []
      }
      return state.items
    }
  },
  mutations: {
    set_items: (state, value) => {
      state.items = value
    }
  },

  actions: {
    // 获取 BET HALL 列表
    async getListBetHall ({ commit, state }) {
      const res = await getListBetHall()
      const result = res.data
      if (result) {
        const items = result.map((x) => {
          return { title: x.title_lang, value: x.hall_id }
        })
        commit('set_items', items)
      } else {
        state.items = []
      }
      return result
    }
  }
}

export default betHallList
