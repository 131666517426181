
import Vue from 'vue'
import * as inbox from '@/api/me-inbox'

interface DataTableQuery {
  page?: number
  itemsPerPage?: number,
  [key: string]: any
}

export default Vue.extend({
  data: (): {
    items: inbox.MessageTitle[],
    ajaxHandle: null|Promise<any>,
    options: DataTableQuery,
    serverTotal: number
  } => ({
    items: [],
    ajaxHandle: null,
    options: {},
    serverTotal: 0
  }),
  computed: {
    headers () {
      return [
        // { text: 'ID', align: 'start', value: 'id' },
        { text: this.$t('modifyLogs.title'), value: 'title' }
      ]
    },
    loading () { return !!this.ajaxHandle }
  },
  methods: {
    selectItem (item: inbox.MessageTitle) {
      this.$emit('input', item)
    },
    getList () {
      this.ajaxHandle = inbox.getList({
          page: this.options.page,
          limit: this.options.itemsPerPage
        }).then(response => {
          this.items = response.data.data
          this.serverTotal = response.data.total
        }).finally(() => {
          this.ajaxHandle = null
        })
    }
  },
  watch: {
    /**
     * Options 有異動的話，自動查詢資料
     */
    options: {
      handler (newValue, oldValue) {
        // 有切換分頁才觸發(多擋一個有總筆數才觸發)
        if ((newValue.page !== oldValue.page || newValue.itemsPerPage !== oldValue.itemsPerPage) && this.serverTotal) {
          this.getList()
        }
      }
    }
  }
})
