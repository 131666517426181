import Vue from 'vue'

/**
 * 必填欄位
 */
export default function required (this: Vue, v) {
  return (
    v !== '' &&
    v !== null &&
    v !== undefined &&
    v.length !== 0
  ) || this.$t('validate.required')
}
