<template lang="pug">
v-container
  v-card.rounded-0.grey.lighten-5(elevation='0')
    v-card-title.pb-1.text-no-wrap
      span.text-h6.font-weight-bold {{ pageTitle }}
      //- 表格相關 按鈕群
      slot(name='buttonGroup')
      //- 頁面解釋 / 備註
      slot(name='remark')
    v-divider.mx-4
    v-card-text
      slot
</template>

<script>
import Vue from 'vue'
import { setDocumentTitle } from '@/utils/domUtil'

// 下面這段主要是更新系統標題用
// REFACTOR: 這段程式另外抽出來會比較好
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    pageTitle () {
      return this.title !== null ? this.title : this.$t(`nav.${this.$route.name}`)
    }
  },
  watch: {
    pageTitle () {
      this.updateTitle()
    }
  },
  methods: {
    updateTitle () {
      setDocumentTitle(`${this.$store.state.app.systemTitle} - ${this.pageTitle}`)
    }
  },
  mounted () {
    this.updateTitle()
  },
  beforeDestroy () {
    setDocumentTitle(this.$store.state.app.systemTitle)
  }
})
</script>
