import Vue from 'vue'
import VueRouter from 'vue-router'
import defaultRoute from '@/router/default-route'

Vue.use(VueRouter)

function createRouter () {
  return new VueRouter({
    mode: 'hash',
    base: '/',
    routes: defaultRoute
  })
}

// 定义一个resetRouter 方法，在退出登录后或token过期后 需要重新登录时，调用即可
export function resetRouter () {
  const newRouter = createRouter()
  // @ts-ignore
  router.matcher = newRouter.matcher
}

const router = createRouter()
export default router
