export default {
  badge: 'Lencana',
  close: 'Tutup',
  dataIterator: {
    noResultsText: 'Tiada rekod yang sepadan ditemui',
    loadingText: 'Memuatkan item...'
  },
  dataTable: {
    itemsPerPageText: 'Baris setiap halaman:',
    ariaLabel: {
      sortDescending: 'Disusun secara menurun.',
      sortAscending: 'Disusun secara menaik.',
      sortNone: 'Tidak disusun.',
      activateNone: 'Aktifkan untuk mengeluarkan susunan.',
      activateDescending: 'Aktifkan untuk menyusun menurun.',
      activateAscending: 'Aktifkan untuk menyusun menaik.'
    },
    sortBy: 'Susun mengikut'
  },
  dataFooter: {
    itemsPerPageText: 'Item setiap halaman:',
    itemsPerPageAll: 'Semua',
    nextPage: 'Halaman seterusnya',
    prevPage: 'Halaman sebelumnya',
    firstPage: 'Halaman pertama',
    lastPage: 'Halaman terakhir',
    pageText: '{0}-{1} daripada {2}'
  },
  datePicker: {
    itemsSelected: '{0} dipilih',
    nextMonthAriaLabel: 'Bulan seterusnya',
    nextYearAriaLabel: 'Tahun seterusnya',
    prevMonthAriaLabel: 'Bulan sebelumnya',
    prevYearAriaLabel: 'Tahun sebelumnya'
  },
  noDataText: 'Tiada data tersedia',
  carousel: {
    prev: 'Visual sebelumnya',
    next: 'Visual seterusnya',
    ariaLabel: {
      delimiter: 'Slide karusel {0} daripada {1}'
    }
  },
  calendar: {
    moreEvents: '{0} lagi'
  },
  input: {
    clear: 'Bersihkan {0}',
    prependAction: '{0} tindakan yang disisipkan di depan',
    appendAction: '{0} tindakan yang disisipkan di belakang'
  },
  fileInput: {
    counter: '{0} fail',
    counterSize: '{0} fail ({1} jumlah keseluruhan)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Navigasi Penomboran',
      next: 'Halaman seterusnya',
      previous: 'Halaman sebelumnya',
      page: 'Pergi ke Halaman {0}',
      currentPage: 'Halaman Semasa, Halaman {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'Penilaian {0} daripada {1}'
    }
  },
  loading: 'Memuatkan...'
}
