module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No announce"])}
      },
      "cn": {
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无公告"])}
      },
      "vi": {
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có thông báo"])}
      },
      "ms": {
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiada pengumuman"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"noData":"No announce"},"cn":{"noData":"无公告"},"vi":{"noData":"Không có thông báo"},"ms":{"noData":"Tiada pengumuman"}}')
  delete Component.options._Ctor
  
}
