import Vue from 'vue'
import * as loadRules from './loadRules'

type NoArgs = string
type hasArgs = [string, ...any]
type isFunction = Function

/**
 * 產生表單驗證規則
 * @param this Vue instance
 * @returns 驗證規則
 */
export function makeValidator (this: Vue) {
  return (rules: (NoArgs|hasArgs|isFunction)[]): Function[] => {
    return rules.map(rule => {
      if (Array.isArray(rule)) {
        const [ruleName, ...args] = rule
        const ruleMaker = loadRules.get(ruleName)
        return ruleMaker.apply(this, args)
      } else if (typeof rule === 'function') {
        return rule.bind(this)
      } else {
        const ruleMaker = loadRules.get(rule)
        return ruleMaker.bind(this)
      }
    })
  }
}

export default {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        makeValidator
      }
    })
  }
}
