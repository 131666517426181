import request from '@/utils/request'
import ResponseFormat, { Pagination } from './response-format'

const url = {
  messagesList: '/Me/messagesList',
  messagesDetail: '/Me/messagesDetail'
}

/**
 * 訊息標頭格式
 */
export interface MessageTitle {
  id: number,
  title: string
}

/**
 * 取列表
 * @returns
 */
export function getList (params: {page?: number, limit?: number} = {}) {
  return request.get(url.messagesList, { params }) as Promise<
    ResponseFormat<Pagination<MessageTitle[]>>
  >
}

/**
 * 訊息內容格式
 */
export interface MessageDetail {
  title: string,
  read_time: number,
  create_time: number,
  sender_account: string,
  content: string
}

/**
 * 取內容
 * @param id 訊息 id
 * @returns
 */
export function getDetail (id: number) {
  return request.get(url.messagesDetail, { params: { id } }) as Promise<ResponseFormat<MessageDetail>>
}
