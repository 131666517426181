<template lang="pug">
v-btn(v-if="!hasStarted" depressed fab small @click='playSound')
  v-icon mdi-music-off
v-btn(v-else depressed fab small @click='stopSound')
  v-icon mdi-music
</template>

<script lag="ts">
export default {
  data () {
    return {
      oscillator: null,
      hasStarted: false
    }
  },
  methods: {
   // 播放声音
   playSound () {
      if (this.$store.state.app.audioContext.state !== 'running') {
        this.$store.state.app.audioContext.resume() // 如果上下文被暂停，恢复上下文
      }
      this.hasStarted = true
    },
    // 停止声音
    stopSound () {
      this.$store.state.app.audioContext.suspend() // 关闭 AudioContext
      this.hasStarted = false
    }
  }
}
</script>
