import storage from 'store'
import keys from '@/store/storage-keys'
import i18n from '@/i18n'
import languages from './languages'
import { EventEmitter } from 'events'

export default {
  namespaced: true,
  state: {
    systemTitle: window.systemTitle ?? '',
    initialized: false,
    languages,
    lang: storage.get(keys.LANG) || 'en',
    loading: false,
    timezone: '+08:00',
    systemMaintenance: false, // 系統維護
    eventHub: new EventEmitter() // 事件傳遞用
  },
  mutations: {
    lang (state, lang: string) {
      state.lang = lang
      storage.set(keys.LANG, lang || 'en')
      i18n.locale = lang
    },
    timezone (state, val) {
      state.timezone = val
    },
    systemMaintenance (state, val) {
      state.systemMaintenance = !!val
    }
  },
  actions: {
    /**
     * 系統初始化
     */
    async initialize ({ state, dispatch }) {
      // 第一次進入，先確定使用者是否有登入
      let whoami = null
      try {
        whoami = await dispatch('user/Whoami', null, { root: true })
      } catch (err) {
        console.error(err)
      }
      if (whoami) {
        // 已登入，拉選單列表並更新
        await dispatch('GenerateRoutes', null, { root: true })
      }
      state.initialized = true
    },
    loading ({ state }) {
      state.loading = true
    },
    loadEnd ({ state }) {
      state.loading = false
    }
  }
}
